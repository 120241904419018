/* eslint-disable dot-notation */
import moment from 'moment';

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const formatDate = (date, format = 'DD/MM/YYYY') =>
  moment(date).format(format);

export function tranformDateForm(formats, parseStrict) {
  return function format(value, originalValue) {
    if (this.isType(value)) return value;

    // eslint-disable-next-line no-param-reassign
    value = moment(originalValue, formats, parseStrict);

    return value.isValid() ? value.toDate() : new Date('');
  };
}

const getImages = (element) => {
  const newData = Object.keys(element).map((item) => {
    if (/\d/.test(item)) {
      const resolution = item.replace(/^\D+/g, '');
      const itemWhitoutNumbers = item.replace(/\d/g, '');
      return {
        [item.replace(/\d/g, '')]: element[
          `${itemWhitoutNumbers}${resolution}`
        ],
        device: itemWhitoutNumbers,
        id: element.id,
        active: element.active,
      };
    }

    return null;
  });

  let device = null;
  return newData.filter((img) => {
    if (img && device !== img?.device) {
      device = img.device;
      return true;
    }
    device = img?.device;
    return false;
  });
};

export const getDeviceImage = (data) => {
  const result = { desktop: [], mobile: [], tablet: [] };
  data.forEach((element) => {
    const desktop = element.find((item) => item.device === 'desktop');
    const mobile = element.find((item) => item.device === 'mobile');
    const tablet = element.find((item) => item.device === 'tablet');
    result.desktop.push(desktop);
    result.mobile.push(mobile);
    result.tablet.push(tablet);
  });
  return result;
};

export const getCarouselArray = (data) => {
  const newCarousel = data.map((element) => {
    const closest = getImages(element);
    return closest;
  });

  return getDeviceImage(newCarousel);
};

export const formatCurrencyValue = (
  value,
  options = { style: 'currency', currency: 'ARS' },
) =>
  new Intl.NumberFormat('es-AR', {
    ...options,
    maximumFractionDigits: 8,
  }).format(value);
