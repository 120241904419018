import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ConfigIndexersCryptoNetworkItem from './CryptoNetworkItem';
import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigIndexersMainItem = ({ name, cryptoNetworks }) => {
  const classes = useStyles();

  const [isCollapse, setIsCollapse] = useState(true);

  const toggleIsCollapse = useCallback(() => {
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  return (
    <>
      <ListItem button onClick={toggleIsCollapse}>
        {isCollapse ? <ExpandLess /> : <ExpandMore />}

        <ListItemText primary={name} />
      </ListItem>

      <Collapse in={isCollapse} timeout="auto" unmountOnExit>
        <List dense disablePadding className={classes.listIndent}>
          {Object.values(cryptoNetworks).map((cryptoNetwork) => (
            <ConfigIndexersCryptoNetworkItem
              key={`crypto-network-${cryptoNetwork.name}`}
              mainName={name}
              name={cryptoNetwork.name}
              currencies={cryptoNetwork.currency}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

ConfigIndexersMainItem.propTypes = {
  name: PropTypes.string.isRequired,
  cryptoNetworks: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      currency: PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          indexers: PropTypes.arrayOf(
            PropTypes.shape({
              active: PropTypes.number.isRequired,
              default: PropTypes.number.isRequired,
              id: PropTypes.number.isRequired,
              name: PropTypes.string.isRequired,
            }),
          ).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

export default ConfigIndexersMainItem;
