// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetQuotations
 * @property {Array.<Object>} data - Listado de cotizaciones
 */

/**
 * Obtener la lista de cotizaciones
 * @returns {Promise<AxiosResponse<ResponseGetQuotations>>} Listado de cotizaciones
 */
const getQuotations = () => axiosInstance.get('/internal/admin/quotations');

export default getQuotations;
