// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ParamsUpdateRetirement
 * @property {String | Number} id - Id de la retiro a modificar
 * @property {''process' | 'liquidate' | 'cancel'} nextState - Nuevo estado
 */

/**
 * Modifica el estado de una retiro
 * @param {ParamsUpdateKriptoPayment} params
 * @returns {Promise<AxiosResponse>}
 */
const updateStateRetirement = (params) => {
  const { id, nextState } = params;

  return axiosInstance.patch(`/internal/admin/retirements/${id}`, {
    next_state: nextState,
  });
};

export default updateStateRetirement;
