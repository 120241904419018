import { object, date } from 'yup';
import moment from 'moment';

import { typesInputs as typesRangeDate } from '../DatepickerRange/utils';
import { formatDate, tranformDateForm } from '../../utils/helpers';

export const getInitialValues = (from) => {
  const exportDates = {
    [typesRangeDate.startDate]: null,
    [typesRangeDate.endDate]: null,
  };

  if (from) {
    const nextDayDate = moment().add(1, 'day');

    exportDates[typesRangeDate.startDate] = formatDate(from);
    exportDates[typesRangeDate.endDate] = formatDate(nextDayDate);
  }

  return { exportDates };
};

export const validationSchema = object().shape({
  exportDates: object().shape({
    [typesRangeDate.startDate]: date()
      .transform(tranformDateForm('DD/MM/YYYY'))
      .nullable()
      .typeError('Fecha Invalida')
      .required('Campo requerido'),
    [typesRangeDate.endDate]: date()
      .transform(tranformDateForm('DD/MM/YYYY'))
      .nullable()
      .typeError('Fecha Invalida')
      .required('Campo requerido'),
  }),
});
