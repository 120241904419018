import { useCallback, useState } from 'react';

/**
 * @typedef {Object} ObjectReturnUseFiltersValue
 * @property {Object} valuesFilters - Valores de los filtros
 * @property {(key, value) => void} setValueFilterByKey - Funcion para cambiar un
 *                                                      valor de un filtro especifico
 * @property {(MouseEvent) => void} handleChangeInputsFilter - Funcion para modificar el
 *                                                           el valor de un filtro a traves de
 *                                                            del onChange de un input
 */

/**
 * Hook para usar multiples filtros
 * @param {Object} initialValues - Valores iniciales
 * @returns {ObjectReturnUseFiltersValue}
 */

export const useFiltersValue = (initialValues = {}) => {
  const [values, setValues] = useState(initialValues);

  const setValueFilterByKey = useCallback(
    (key, newValue) =>
      setValues((prevValues) => ({ ...prevValues, [key]: newValue })),
    [],
  );

  const handleChangeInputsFilter = useCallback(
    (event) => {
      const { name, value } = event.target;

      setValueFilterByKey(name, value);
    },
    [setValueFilterByKey],
  );

  return {
    valuesFilters: values,
    setValueFilterByKey,
    handleChangeInputsFilter,
  };
};
