import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { useFormik, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';

import CardWithProgressLoading from '../CardWithProgressLoading';
import Select from '../Select';
import RenderFields from '../RenderFields';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { useRequest, useLazyRequest } from '../../hooks';
import { getConfigWebPage, createReport } from '../../api';
import {
  initialValues,
  validationSchema,
  getOptiosFiltersValues,
  getFilters,
} from './utils';
import { getHelperTextField, hasError } from '../../utils/errorInput';

import styles from './styles';

const useStyles = makeStyles(styles);

const ReportsFilters = ({ reloadDownloadsReports }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [listTypes, setListTypes] = useState([]);
  const [configReports, loadingConfigReports] = useRequest(
    {
      initialState: [],
      request: getConfigWebPage,
      payload: {
        webPage: 'admin',
        route: '/reports',
      },
      transformResponse: ({ data }) => data.reports,
      withPostSuccess: (response) => {
        const parseListTypes = response.map(({ type, value }) => ({
          label: type,
          value,
        }));

        setListTypes(parseListTypes);
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'Lo sentimos lo datos no pudieron ser cargados, intententelo nuevamente mas tarde',
          { variant: 'error' },
        );
      },
    },
    [],
  );

  const [completed, setCompleted] = useState(false);
  const [, loadingSendReport, , sendReport] = useLazyRequest({
    request: createReport,
    withPostSuccess: () => {
      enqueueSnackbar('Se genero el reporte satisfactoriamente', {
        variant: 'success',
      });

      setCompleted(true);
      reloadDownloadsReports();
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo generar el reporte, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const executeSubmit = useCallback(
    (values) => {
      setCompleted(false);
      sendReport(values);
    },
    [sendReport],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema: validationSchema(configReports),
    onSubmit: executeSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setValues,
    resetForm,
  } = formikBag;

  useEffect(() => {
    if (completed) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const hasValueType = useMemo(() => !isEmpty(values.type), [values.type]);

  const dataFilters = useMemo(() => {
    if (!hasValueType) return [];

    return getFilters(configReports, values.type);
  }, [configReports, hasValueType, values.type]);

  const handleChangeType = useCallback(
    (event) => {
      const { value, name } = event.target ?? event.currentTarget;

      const filters = getFilters(configReports, value);
      const formValues = {
        [name]: value,
        options: getOptiosFiltersValues(filters),
      };

      setValues(formValues);
    },
    [configReports, setValues],
  );

  return (
    <CardWithProgressLoading loading={loadingConfigReports}>
      <Typography variant="h5" className={classes.titleCard}>
        FILTROS
      </Typography>

      <FormikProvider value={formikBag}>
        <Grid
          component="form"
          onSubmit={handleSubmit}
          container
          spacing={2}
          justify="center"
        >
          <Grid item xs={12}>
            <Select
              label="Tipo de reporte"
              error={hasError(errors, touched, 'type')}
              helperText={getHelperTextField(errors, touched, 'type')}
              autoComplete="off"
              {...getFieldProps('type')}
              onChange={handleChangeType}
              data={listTypes}
            />
          </Grid>

          {dataFilters.map(
            ({ type: component, label, name_of_param: name, options }) => {
              const nameOption = `options.${name}`;

              return (
                <Grid
                  item
                  xs={12}
                  key={`filter-component-${component}-${nameOption}`}
                >
                  <RenderFields
                    component={component}
                    properties={{
                      label,
                      error: hasError(errors, touched, nameOption),
                      helperText: getHelperTextField(
                        errors,
                        touched,
                        nameOption,
                      ),
                      data: options?.map(({ key, value }) => ({
                        label: key,
                        value,
                      })),
                      ...getFieldProps(nameOption),
                    }}
                  />
                </Grid>
              );
            },
          )}

          {hasValueType && (
            <Grid item xs={4}>
              <ButtonWithCirculaProgress
                type="submit"
                color="primary"
                loading={loadingSendReport}
                label="CREAR REPORTE"
              />
            </Grid>
          )}
        </Grid>
      </FormikProvider>
    </CardWithProgressLoading>
  );
};

ReportsFilters.propTypes = {
  reloadDownloadsReports: PropTypes.func.isRequired,
};

export default ReportsFilters;
