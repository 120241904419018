// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ExtrasMetadata
 * @property {String} key - Nombre / key
 * @property {String} value - Valor
 */

/**
 * @typedef {Object} MetadataParams
 * @property {ExtrasMetadata[]} extras
 */

/**
 * @typedef {Object} DataParamsUpdateBank
 * @property {String} name - Nombre del banco
 * @property {String} country - alpha3 correspondiente al pais
 * @property {String} currency_id - Moneda asociada (id)
 * @property {String} account_type - Tipo de cuenta
 * @property {String} account_number - 	Numero de cuenta
 * @property {MetadataParams} metadata - json con la data no estructurada
 */

/**
 * @typedef {Object} ParamsUpdateKriptoBank
 * @property {String | Number} id - Id del banco a buscar
 * @property {DataParamsUpdateBank} data - Datos a actualizar
 */

/**
 * Actualiza los datos de un banco
 * @param {ParamsUpdateKriptoBank} params
 * @returns {Promise<AxiosResponse>}
 */
const updateBank = (params) => {
  const { id, data } = params;

  return axiosInstance.patch(`/internal/admin/config/banks/${id}`, data);
};

export default updateBank;
