// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetClientById
 * @property {Object} data - Objeto con los datos del cliente
 */

/**
 * Obtener datos del cleinte segun su ID
 * @param {String | Number} clientId - Id del cliente a buscar
 * @returns {Promise<AxiosResponse<ResponseGetClientById>>} Informacion del usuario
 */
const getClientById = (clientId) =>
  axiosInstance.get(`/internal/admin/clients/${clientId}`);

export default getClientById;
