import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useFormik, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import DatepickerRange from '../DatepickerRange';

import { useLazyRequest } from '../../hooks';
import { updateDashboardTransactions, createDashboardReport } from '../../api';
import { typesInputs as typesRangeDate } from '../DatepickerRange/utils';

import { validationSchema, getInitialValues } from './utils';

const DashboardActions = ({ filtersSearch, info }) => {
  const { address, currencyNetworkId } = filtersSearch;
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingUpdate, , executeUpdate] = useLazyRequest({
    request: updateDashboardTransactions,
    withPostSuccess: () => {
      enqueueSnackbar(
        'La información de la wallet será actualizada durante los próximos minutos',
        { variant: 'success' },
      );
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'Ha ocurrido un error, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const onUpdateTransaction = useCallback(() => {
    executeUpdate({ address, currencyNetworkId });
  }, [executeUpdate, address, currencyNetworkId]);

  const [, loadingBuildExportable, , executeBuildExportable] = useLazyRequest({
    request: createDashboardReport,
    withPostSuccess: (response) => {
      window.open(response.data.url, '_blank');
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'Ha ocurrido un error, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const onBuildExportable = useCallback(
    (values) => {
      executeBuildExportable({
        address,
        currencyNetworkId,
        from: values.exportDates[typesRangeDate.startDate],
        to: values.exportDates[typesRangeDate.endDate],
      });
    },
    [executeBuildExportable, address, currencyNetworkId],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(info.from),
    validationSchema,
    onSubmit: onBuildExportable,
  });

  const { submitForm } = formikBag;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Acciones</Typography>
      </Grid>

      <Grid item xs={12}>
        <ButtonWithCirculaProgress
          color="primary"
          handleButtonClick={onUpdateTransaction}
          loading={loadingUpdate}
          label="ACTUALIZAR"
          gutters={false}
        />
      </Grid>

      <FormikProvider value={formikBag}>
        <Grid item container spacing={2}>
          <Grid item xs="auto">
            <ButtonWithCirculaProgress
              color="secondary"
              handleButtonClick={submitForm}
              loading={loadingBuildExportable}
              label="EXPORTAR POR FECHA"
              gutters={false}
            />
          </Grid>

          <Grid item xs="auto">
            <DatepickerRange name="exportDates" showTime={false} size="small" />
          </Grid>
        </Grid>
      </FormikProvider>
    </Grid>
  );
};

DashboardActions.propTypes = {
  filtersSearch: PropTypes.shape({
    address: PropTypes.string.isRequired,
    currencyNetworkId: PropTypes.number.isRequired,
  }).isRequired,
  info: PropTypes.shape({
    transactions: PropTypes.number.isRequired,
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

export default DashboardActions;
