import { useState, useCallback } from 'react';

/**
 * @typedef {Object} ObjectReturnUseToggleModal
 * @property {Boolean} open - Boolean para comprobar que se puede mostrar el modal
 * @property {() => void} toggleOpen - Funcion para ejecutar el cambio
 */

/**
 * Hook para mostrar Modal
 * @returns {ObjectReturnUseToggleModal}
 */
export const useToggleModal = () => {
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return { open, toggleOpen };
};
