import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TextField,
  MenuItem,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const Select = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  fullWidth,
  error,
  helperText,
  loading,
  data,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      variant="outlined"
      select
      InputProps={
        loading
          ? {
              endAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ positionStart: classes.inputAdornment }}
                >
                  <CircularProgress color="primary" size={20} />
                </InputAdornment>
              ),
            }
          : {}
      }
      {...rest}
    >
      <MenuItem value="" disabled>
        Selecciona...
      </MenuItem>
      {data.map((typeItem) => (
        <MenuItem key={typeItem.value} value={typeItem.value}>
          {typeItem.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Select.defaultProps = {
  label: null,
  onBlur: () => {},
  disabled: false,
  fullWidth: true,
  error: false,
  helperText: null,
  loading: false,
};

export default memo(Select);
