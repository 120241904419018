const AUTH_TOKEN_KEY = 'authToken';

/**
 * Obtiene el token de seguridad del localStorage
 * @returns {String} token de seguridad
 */
export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

/**
 * Setea el token de seguridad del localStorage
 * @param {String} value - Valor del Token
 */
export const setAuthToken = (value) =>
  localStorage.setItem(AUTH_TOKEN_KEY, value);

/**
 * Elimina el token de seguridad del localStorage
 */
export const removeAuthToken = () => localStorage.removeItem(AUTH_TOKEN_KEY);
