/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CashIn = (props) => (
  <SvgIcon viewBox="0 0 467.527 467.527" id="Capa_1" {...props}>
    <g>
      <path
        d="M467.41,88.59c-0.674-3.525-4.069-5.837-7.605-5.163l-42.146,8.064c-11.53,2.212-21.351,9.398-26.949,19.729l-0.194,0.362
        c-11.818,21.783-28.697,40.974-48.814,55.499c-6.849,4.946-13.351,10.483-19.326,16.457l-60.944,60.937
        c-7.711,5.854-18.597,5.133-25.47-1.74c-3.665-3.665-5.684-8.54-5.684-13.726c0-5.193,2.019-10.071,5.684-13.736l44.022-44.023
        c14.996-14.995,18.445-38.141,8.387-56.285c-8.266-14.91-23.963-24.172-40.966-24.172c-0.918,0-5.224,0.227-5.224,0.227
        c-11.391,0.711-22.112,5.55-30.188,13.626l-28.461,28.461c-6.746,6.745-10.606,15.712-10.869,25.249l-0.544,19.758l-29.168,29.168
        l3.268-80.271c0.113-2.885,1.281-5.616,3.293-7.69l94.112-97.15c5.622-5.604,13.09-8.689,21.035-8.689h113.539
        c3.59,0,6.5-2.91,6.5-6.5s-2.91-6.5-6.5-6.5H264.657c-11.434,0-22.182,4.451-30.263,12.532c-0.024,0.024-0.049,0.049-0.073,0.074
        l-94.146,97.187c-4.243,4.375-6.711,10.139-6.948,16.22l-3.841,94.351L1.904,348.327C0.685,349.546,0,351.199,0,352.923
        s0.685,3.377,1.904,4.596l107.623,107.623c1.269,1.269,2.933,1.904,4.596,1.904s3.327-0.635,4.596-1.904L339.92,243.941
        c2.539-2.539,2.539-6.654,0-9.192l-25.187-25.187l16.833-16.831c5.487-5.486,11.458-10.57,17.745-15.111
        c21.688-15.66,39.888-36.352,52.64-59.856l0.196-0.364c3.727-6.876,10.273-11.667,17.958-13.141l42.144-8.063
        C465.773,95.521,468.085,92.116,467.41,88.59z M226.768,206.08c-6.121,6.121-9.491,14.263-9.491,22.929
        c0,8.659,3.371,16.798,9.491,22.918c6.29,6.29,14.594,9.493,22.936,9.493c7.059,0,14.145-2.294,20.056-6.953
        c0.201-0.158,0.392-0.328,0.573-0.509l18.733-18.731l7.791,7.791L117.795,422.078l-72.832-72.832l179.061-179.061l19.32,19.319
        L226.768,206.08z M221.184,113.837c5.835-5.834,13.579-9.331,21.788-9.843c0,0,3.764-0.202,4.432-0.202
        c12.28,0,23.62,6.696,29.597,17.475c7.261,13.1,4.708,29.873-6.21,40.79l-1.781,1.781l-36.712-36.711
        c-2.538-2.538-6.654-2.538-9.192,0l-12.725,12.725l0-15.21L221.184,113.837z M185.657,158.714c0.171-6.2,2.68-12.03,7.066-16.415
        l4.657-4.657v15.209l-11.889,11.889L185.657,158.714z M326.132,239.345L114.123,451.353l-98.431-98.43l212.009-212.009
        l32.116,32.115l-7.282,7.282l-23.915-23.916c-2.538-2.538-6.654-2.538-9.192,0L31.174,344.649c-2.539,2.539-2.539,6.654,0,9.192
        l82.024,82.024c1.269,1.269,2.933,1.904,4.596,1.904s3.327-0.635,4.596-1.904l188.252-188.253c2.539-2.539,2.539-6.654,0.001-9.192
        l-12.386-12.387l7.282-7.281L326.132,239.345z"
      />
      <path
        d="M192.243,274.803c-18.173-18.173-41.779-23.039-53.74-11.078s-7.095,35.566,11.078,53.74
        c11.455,11.455,25.067,17.623,36.562,17.622c6.742,0,12.757-2.123,17.179-6.544c6.146-6.146,8.063-15.431,5.399-26.144
        C206.347,292.855,200.495,283.055,192.243,274.803z M194.129,319.351c-5.741,5.742-21.674,2.604-35.355-11.078
        c-13.682-13.682-16.819-29.614-11.078-35.355c1.803-1.803,4.611-2.73,8.04-2.73c7.487,0,17.931,4.424,27.316,13.809
        c6.51,6.51,11.268,14.361,13.054,21.541C197.603,311.561,196.883,316.597,194.129,319.351z"
      />
    </g>
  </SvgIcon>
);

export default CashIn;
