import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import styles from './styles';

const useStyles = makeStyles(styles);

const CardComponent = ({ id, url, index, handleDelete }) => {
  const ref = useRef(null);
  const classes = useStyles();
  const handleClick = () => handleDelete(id);

  return (
    <div className={classes.card} id={id} ref={ref} key={`data-${index}`}>
      <IconButton className={classes.iconDelete} onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      <img className={classes.image} src={url} alt="data-text" />
    </div>
  );
};

CardComponent.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default CardComponent;
