import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { updateWallet } from '../../api';

const SwitchActiveWallet = ({ active, walletId, refetchWallets }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(active);

  const handleChange = useCallback(
    async (event) => {
      event.preventDefault();
      const currrentActive = event.target.checked;

      try {
        await updateWallet({ id: walletId, active: currrentActive });

        setChecked(currrentActive);

        enqueueSnackbar(
          `Wallet ${
            !currrentActive ? 'desactivada' : 'activada'
          } correctamente`,
          {
            variant: 'success',
            key: 'successUpdateActiveWallet',
          },
        );

        setTimeout(refetchWallets, 500);
      } catch (err) {
        enqueueSnackbar('Hubo error, intentelo nuevamente', {
          variant: 'error',
          key: 'errorUpdateActiveWallet',
        });
      }
    },
    [enqueueSnackbar, walletId, refetchWallets],
  );

  return (
    <FormControlLabel
      control={
        <Switch
          name={`active-wallet-${walletId}`}
          checked={checked}
          onChange={handleChange}
          color="primary"
        />
      }
      label="Activa?"
    />
  );
};

SwitchActiveWallet.propTypes = {
  active: PropTypes.bool.isRequired,
  walletId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  refetchWallets: PropTypes.func.isRequired,
};

export default memo(SwitchActiveWallet);
