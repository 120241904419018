import { has, get } from 'lodash';

/**
 * Comprueba si existe la key en el objeto error | touched
 * @param {Object} errors - Objeto de errores
 * @param {Object} touched - Objeto de touched
 * @param {String | Array} pathField - Key del input
 * @return {Boolean} Retorna true de existir la key, de lo contrario false
 */
export const hasError = (errors, touched, pathField) =>
  has(errors, pathField) && has(touched, pathField);

/**
 * Obtiene el mensaje de erroe segun la key
 * @param {Object} errors - Objeto de errores
 * @param {Object} touched - Objeto de touched
 * @param {String | Array} pathField - Key del input
 * @param {String} defaultHelper - Mensaje por defecto
 * @returns {String} Mensaje de error
 */
export const getHelperTextField = (
  errors,
  touched,
  pathField,
  defaultHelper = ' ',
) =>
  hasError(errors, touched, pathField) ? get(errors, pathField) : defaultHelper;
