/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const WalletsPayment = (props) => (
  <SvgIcon viewBox="0 0 64 64" id="Layer_1_1_" {...props}>
    <g id="Guide">
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
    <rect x="16" y="20" width="2" height="2" />
    <rect x="44" y="20" width="2" height="2" />
    <rect x="7" y="41" width="2" height="2" />
    <rect x="7" y="37" width="2" height="2" />
    <rect x="7" y="33" width="2" height="2" />
    <path
      d="M58,29.101V25V15c0-0.553-0.447-1-1-1h-8.353C45.852,6.981,39.003,2,31,2S16.148,6.981,13.353,14H8c-3.309,0-6,2.691-6,6v36
      c0,3.309,2.691,6,6,6h49c0.553,0,1-0.447,1-1V48.899c2.279-0.465,4-2.484,4-4.899V34C62,31.586,60.279,29.566,58,29.101z M60,34
      c0,0.189-0.022,0.372-0.056,0.551c-0.01,0.056-0.026,0.109-0.04,0.164c-0.03,0.123-0.067,0.244-0.112,0.361
      c-0.022,0.056-0.044,0.112-0.07,0.167c-0.054,0.117-0.116,0.23-0.184,0.339c-0.026,0.041-0.048,0.085-0.076,0.125
      c-0.1,0.144-0.21,0.28-0.333,0.404c-0.02,0.021-0.044,0.037-0.065,0.057c-0.106,0.101-0.219,0.195-0.339,0.28
      c-0.046,0.032-0.094,0.061-0.141,0.091c-0.106,0.066-0.215,0.126-0.329,0.179c-0.051,0.024-0.102,0.048-0.155,0.069
      c-0.032,0.013-0.068,0.02-0.1,0.031v-5.645C59.164,31.584,60,32.696,60,34z M49.316,16H56v8h-6.248
      c0.011-0.068,0.015-0.136,0.025-0.204c0.056-0.367,0.101-0.733,0.135-1.097c0.012-0.125,0.023-0.25,0.033-0.375
      C49.978,21.88,50,21.437,50,21c0-0.554-0.034-1.107-0.084-1.659c-0.017-0.191-0.036-0.38-0.058-0.569
      c-0.044-0.361-0.1-0.72-0.165-1.079c-0.079-0.45-0.178-0.893-0.289-1.331C49.373,16.241,49.35,16.12,49.316,16z M31,4
      c7.406,0,13.704,4.768,16.029,11.389c0.141,0.403,0.264,0.808,0.374,1.215c0.026,0.097,0.051,0.195,0.075,0.293
      c0.091,0.362,0.171,0.724,0.238,1.088c0.038,0.21,0.067,0.422,0.097,0.634c0.04,0.275,0.082,0.549,0.108,0.824
      C47.968,19.957,48,20.475,48,21c0,0.564-0.039,1.138-0.101,1.714c-0.019,0.175-0.048,0.351-0.072,0.527
      c-0.035,0.252-0.084,0.505-0.13,0.758h-7.055c-0.598-1.685-1.913-3.005-3.555-3.619C38.26,19.285,39,17.729,39,16
      c0-3.309-2.691-6-6-6V8h-2v2h-2V8h-2v2h-1c-0.553,0-1,0.447-1,1v10v3H14.303c-0.046-0.253-0.095-0.506-0.13-0.758
      c-0.024-0.176-0.054-0.352-0.072-0.527C14.039,22.138,14,21.564,14,21c0-0.525,0.032-1.043,0.079-1.556
      c0.026-0.275,0.068-0.55,0.108-0.824c0.03-0.212,0.059-0.424,0.097-0.634c0.067-0.364,0.147-0.727,0.238-1.088
      c0.024-0.098,0.049-0.195,0.075-0.293c0.11-0.407,0.233-0.813,0.374-1.215C17.296,8.768,23.594,4,31,4z M33,12c2.206,0,4,1.794,4,4
      s-1.794,4-4,4h-6v-8H33z M27,22h6h2c1.454,0,2.764,0.793,3.464,2H27V22z M8,16h4.684c-0.033,0.12-0.056,0.241-0.087,0.362
      c-0.111,0.439-0.21,0.881-0.289,1.331c-0.065,0.359-0.121,0.718-0.165,1.079c-0.022,0.189-0.042,0.378-0.058,0.569
      C12.034,19.893,12,20.446,12,21c0,0.437,0.022,0.88,0.055,1.324c0.009,0.125,0.021,0.25,0.033,0.375
      c0.034,0.365,0.079,0.73,0.135,1.097c0.01,0.068,0.014,0.135,0.025,0.204H8c-2.206,0-4-1.794-4-4S5.794,16,8,16z M9,60V45H7v14.858
      C5.279,59.411,4,57.859,4,56V24.469c0.018,0.016,0.039,0.027,0.057,0.042c0.233,0.204,0.482,0.39,0.745,0.556
      c0.042,0.027,0.084,0.052,0.127,0.077c0.27,0.162,0.553,0.305,0.848,0.423c0.041,0.016,0.082,0.03,0.123,0.045
      c0.306,0.115,0.622,0.209,0.95,0.273c0.029,0.006,0.059,0.008,0.089,0.014C7.284,25.962,7.637,26,8,26h5.462H26h13.899h8.639H56v4v7
      h-3v-2c0-0.553-0.447-1-1-1h-4c-0.553,0-1,0.447-1,1v2h-6c-0.304,0-0.591,0.138-0.781,0.375l-4,5c-0.292,0.365-0.292,0.885,0,1.25
      l4,5C40.409,48.862,40.696,49,41,49h6v2c0,0.553,0.447,1,1,1h4c0.553,0,1-0.447,1-1v-2h3v11H9z M47,47h-5.52l-3.2-4l3.2-4H47V47z
      M49,48V38v-2h2v2v10v2h-2V48z M60,44c0,1.654-1.346,3-3,3h-4v-8h4c0.318,0,0.626-0.037,0.928-0.094
      c0.095-0.018,0.185-0.047,0.278-0.07c0.205-0.051,0.406-0.111,0.6-0.187c0.099-0.039,0.194-0.081,0.29-0.125
      c0.184-0.086,0.36-0.182,0.531-0.288c0.084-0.052,0.169-0.102,0.249-0.159c0.04-0.028,0.085-0.05,0.125-0.079V44z"
    />
    <rect x="52" y="56" width="2" height="2" />
  </SvgIcon>
);

export default WalletsPayment;
