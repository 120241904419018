import { useRef, useEffect } from 'react';

/**
 * Hook para identificar el primer render
 * @returns {Boolean}
 */
export const useFirstRender = () => {
  const isMountRef = useRef(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};
