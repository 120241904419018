import axios from 'axios';
import { isEmpty } from 'lodash';

import { getAuthToken, removeAuthToken } from '../utils';

export const generatePath = () => {
  const prod = 'https://app.kriptonmarket.com';
  const staging = 'https://staging-app.kriptonmarket.com';
  if (
    process.env.REACT_APP_ENVIRONMENT ||
    process.env.NODE_ENV === 'development'
  ) {
    return staging;
  }
  return prod;
};

const BASE_URL = generatePath();

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();

    if (!isEmpty(token)) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error) {
      if (
        error.response.status === 401 &&
        error.response.config.url !== '/public/user/login'
      ) {
        removeAuthToken();
        window.location.assign('/login');
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
