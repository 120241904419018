// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} BeneficiarieItem
 * @property {Number | String} id - Identificador del beneficiario
 * @property {String} name - Nombre del beneficiario
 */

/**
 * @typedef {Object} ResponseGetBeneficiaries
 * @property {Array.<BeneficiarieItem>} data - Lista de beneficarios
 */

/**
 * @typedef TypeBeneficiaries
 * @type {'clients' | 'merchants'}
 */

/**
 * Obtener las lista de beneficiarios segun el tipo de recurso
 * @param {TypeBeneficiaries} type Tipo de recursos a buscar
 * @returns {Promise<AxiosResponse<ResponseGetBeneficiaries>>} Listado de Beneficarios
 */
const getBeneficiaries = (type = 'clients') =>
  axiosInstance.get(`/internal/admin/beneficiaries/${type}`);

export default getBeneficiaries;
