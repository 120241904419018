import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import { useSnackbar } from 'notistack';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import { getHelperTextField, hasError } from '../../utils';
import { useLazyRequest } from '../../hooks';
import { addServicesConfigRate } from '../../api';

const kinds = [
  {
    value: 'commission',
    label: 'commission',
  },
  {
    value: 'tax',
    label: 'tax',
  },
];

const keysDescription = [
  {
    value: 'basic_commission',
    label: 'basic_commission',
  },
  {
    value: 'basic_tax',
    label: 'basic_tax',
  },
];

const typesConfig = [
  {
    value: 'currency',
    label: 'Moneda',
  },
  {
    value: 'country',
    label: 'País',
  },
];

const getInitialValues = (data) => ({
  kind: data.kind ?? '',
  amount: data.amount ?? '',
  keyDescription: data.key_description ?? '',
  // eslint-disable-next-line no-nested-ternary
  typeConfig: data.currency_id
    ? typesConfig[0].value
    : data.country
    ? typesConfig[1].value
    : '',
  currency: data.currency_id ?? '',
  country: data.country ?? '',
});

const validationSchema = object().shape({
  kind: string().required('Campo Requerido'),
  amount: string().required('Campo Requerido'),
  keyDescription: string().required('Campo Requerido'),
  typeConfig: string().required('Campo Requerido'),
  currency: string().when('typeConfig', {
    is: (val) => val === typesConfig[0].value,
    then: string().required('Campo Requerido'),
    otherwise: string().notRequired(),
  }),
  country: string().when('typeConfig', {
    is: (val) => val === typesConfig[1].value,
    then: string().required('Campo Requerido'),
    otherwise: string().notRequired(),
  }),
});

const ServicesConfigForm = ({
  isEdit = false,
  data,
  serviceId,
  countries,
  currencies,
  onSuccess,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const successMessage = useMemo(
    () =>
      isEdit
        ? 'Configuración editada correctamente'
        : 'Configuración creada correctamente',
    [isEdit],
  );

  const [, , , executeAddConfig] = useLazyRequest({
    request: addServicesConfigRate,
    withPostSuccess: () => {
      enqueueSnackbar(successMessage, {
        variant: 'success',
        key: 'successServicesConfigAdd',
      });

      onSuccess();
    },
    withPostFailure: () => {
      enqueueSnackbar('Hubo error, intentelo nuevamente', {
        variant: 'error',
        key: 'errorServicesConfigAdd',
      });
    },
  });

  const handleSubmit = useCallback(
    async (values) => {
      const {
        kind,
        amount,
        keyDescription,
        typeConfig,
        currency,
        country,
      } = values;

      await executeAddConfig({
        kind,
        amount,
        keyDescription,
        ...(typeConfig === 'country' && { country }),
        ...(typeConfig === 'currency' && currency && { currencyId: currency }),
        kriptoServiceId: serviceId,
      });
    },
    [executeAddConfig, serviceId],
  );

  const formatInputAmount = useCallback((e) => {
    const exceptThisSymbols = ['e', 'E', '+', '-', ','];
    return exceptThisSymbols.includes(e.key) && e.preventDefault();
  }, []);

  return (
    <Formik
      initialValues={getInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        dirty,
      }) => (
        <Grid item container component={Form} spacing={1}>
          <Grid item xs={12}>
            <TextField
              name="kind"
              label="Tipo"
              value={values.kind}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'kind')}
              helperText={getHelperTextField(errors, touched, 'kind')}
              select
              disabled={isEdit}
            >
              <MenuItem value="" disabled>
                Selecciona...
              </MenuItem>
              {kinds.map((kind) => (
                <MenuItem key={kind.value} value={kind.value}>
                  {kind.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="amount"
              label="Monto"
              value={values.amount}
              onChange={handleChange}
              onKeyDown={formatInputAmount}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              error={hasError(errors, touched, 'amount')}
              helperText={getHelperTextField(errors, touched, 'amount')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="keyDescription"
              label="Descripción"
              value={values.keyDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'keyDescription')}
              helperText={getHelperTextField(errors, touched, 'keyDescription')}
              select
              disabled={isEdit}
            >
              <MenuItem value="" disabled>
                Selecciona...
              </MenuItem>
              {keysDescription.map((keyD) => (
                <MenuItem key={keyD.value} value={keyD.value}>
                  {keyD.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel>Tipo de configuración</FormLabel>

              <RadioGroup
                row
                name="typeConfig"
                value={values.typeConfig}
                onChange={handleChange}
              >
                {typesConfig.map((typeConfig) => (
                  <FormControlLabel
                    key={typeConfig.value}
                    value={typeConfig.value}
                    control={<Radio color="default" disabled={isEdit} />}
                    label={typeConfig.label}
                  />
                ))}
              </RadioGroup>

              {hasError(errors, touched, 'typeConfig') && (
                <FormHelperText error variant="outlined">
                  {getHelperTextField(errors, touched, 'typeConfig')}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {values.typeConfig === typesConfig[0].value && (
            <Grid item xs={12}>
              <TextField
                name="currency"
                label={typesConfig[0].label}
                value={values.currency}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                fullWidth
                error={hasError(errors, touched, 'currency')}
                helperText={getHelperTextField(errors, touched, 'currency')}
                select
                disabled={isEdit}
              >
                <MenuItem value="" disabled>
                  Selecciona...
                </MenuItem>
                {currencies.map((currency) => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {currency.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {values.typeConfig === typesConfig[1].value && (
            <Grid item xs={12}>
              <TextField
                name="country"
                label={typesConfig[1].label}
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                fullWidth
                error={hasError(errors, touched, 'country')}
                helperText={getHelperTextField(errors, touched, 'country')}
                select
                disabled={isEdit}
              >
                <MenuItem value="" disabled>
                  Selecciona...
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item container spacing={3} justify="center">
            <Grid item xs="auto">
              <ButtonWithCirculaProgress
                type="submit"
                loading={isSubmitting}
                disabled={!dirty}
                label="Guardar"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

ServicesConfigForm.propTypes = {
  isEdit: PropTypes.bool,
  serviceId: PropTypes.number,
  data: PropTypes.shape({
    active: PropTypes.bool,
    amount: PropTypes.string,
    country: PropTypes.string,
    created_at: PropTypes.string,
    created_by: PropTypes.number,
    currency_id: PropTypes.string,
    deactivated_by: PropTypes.string,
    id: PropTypes.number,
    key_description: PropTypes.string,
    kind: PropTypes.string,
    kripto_service_id: PropTypes.number,
    updated_at: PropTypes.string,
  }),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onSuccess: PropTypes.func.isRequired,
};

ServicesConfigForm.defaultProps = {
  isEdit: false,
  data: {},
  countries: [],
  currencies: [],
  serviceId: null,
};

export default ServicesConfigForm;
