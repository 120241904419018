import { useState, useCallback, useEffect } from 'react';
import { isNumber, gte } from 'lodash';

const isWithin = (min, max, value) =>
  isNumber(min) &&
  isNumber(max) &&
  isNumber(value) &&
  gte(value, min) &&
  gte(max, value);

const executeAsyncRequest = async ({
  values,
  request,
  onPrefetch,
  onSuccess,
  onError,
  onPostFetch,
  onFinally,
}) => {
  try {
    onPrefetch();
    const response = await request(values);
    const in200s = isWithin(200, 299, response.status);

    if (in200s) {
      onSuccess(response.data);
    } else {
      onError({
        problem: response.problem,
        errorData: response.data,
        status: response.status,
      });
    }
    onPostFetch(response);
  } catch (err) {
    const dataErr = err?.response || err;

    onError(dataErr);
  } finally {
    onFinally();
  }
};

export const useLazyRequest = ({
  request,
  withPostSuccess,
  withPostFailure,
  initialState = null,
  withPostFetch,
  transformResponse = (response) => response,
}) => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(
    (values) =>
      executeAsyncRequest({
        values,
        request,
        onPrefetch: () => {
          setLoading(true);
          setState(initialState);
          setError(null);
        },
        onSuccess: (data) => {
          const transformedResponse = data
            ? transformResponse(data)
            : undefined;
          setState(transformedResponse);

          withPostSuccess?.(transformedResponse);
        },
        onError: (errorInfo) => {
          setError(() => errorInfo);

          withPostFailure?.(errorInfo);
        },
        onPostFetch: (response) => {
          if (response.data) {
            withPostFetch?.(response.data);
          }
        },
        onFinally: () => {
          setLoading(false);
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialState, request, withPostFailure, withPostSuccess],
  );

  return [state, loading, error, sendRequest];
};

export const useRequest = (
  {
    request,
    payload,
    withPostSuccess,
    withPostFailure,
    initialState = null,
    withPostFetch,
    transformResponse = (response) => response,
  },
  dependencies,
) => {
  const [state, loading, error, sendRequest] = useLazyRequest({
    request,
    withPostSuccess,
    withPostFailure,
    initialState,
    withPostFetch,
    transformResponse,
  });

  useEffect(
    () => {
      sendRequest(payload);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
  );

  return [state, loading, error, sendRequest];
};
