export default (theme) => ({
  root: {
    '& > .MuiListItem-root:not(:first-child)': {
      marginTop: 16,
      borderTop: `1px dashed ${theme.palette.divider}`,
    },
  },
  listIndent: {
    marginLeft: 27,
    borderLeft: `1px dashed ${theme.palette.divider}`,
  },
  listItemCurrencyText: {
    minWidth: 300,
  },
  switchersContainer: {
    display: 'flex',
    width: '100%',
    gap: 32,
  },
  formControLabel: {
    margin: 0,
  },
  badge: {
    '& .MuiBadge-badge': {
      fontSize: 10,
      fontWeight: 700,
      padding: 3,
      height: 'auto',
    },

    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: -2,
    },
  },
});
