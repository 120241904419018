import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import classNames from 'classnames';

import TableList from '../TableList';
import SwitchActiveWallet from '../SwitchActiveWallet';

import { getWalletsPayments } from '../../api';
import { useDataTableOptions } from '../../hooks';

import styles from './styles';

const useStyles = makeStyles(styles);

const TableWalletsPayment = ({ currencyNetwork, gutterTop }) => {
  const classes = useStyles();
  const {
    options: { loading: loadingFetch, data, totalRows, perPage, page },
    setOptionByKey,
  } = useDataTableOptions({
    loading: true,
    data: [],
    totalRows: 0,
    perPage: 25,
    page: 1,
  });

  const [countDataState, setCountDataState] = useState({
    actives: 0,
    noActives: 0,
  });

  const fetchWalletsPayment = useCallback(
    async ({ perPage: perPageParam, page: pageParam }) => {
      setOptionByKey('loading', true);
      setCountDataState({
        actives: 0,
        noActives: 0,
      });

      try {
        const { data: dataResponse } = await getWalletsPayments({
          pageNumber: pageParam,
          pageSize: perPageParam,
          currencyNetwork,
        });

        setOptionByKey('data', dataResponse.data);
        setOptionByKey('totalRows', dataResponse.total_elements);
        setCountDataState({
          actives: dataResponse.active,
          noActives: dataResponse.deactivate,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setOptionByKey('loading', false);
      }
    },
    [setOptionByKey, currencyNetwork],
  );

  useEffect(() => {
    fetchWalletsPayment({ page, perPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWalletsPayment, currencyNetwork]);

  const handlePageChange = useCallback(
    async (newPage) => {
      await fetchWalletsPayment({
        perPage,
        page: newPage,
      });

      setOptionByKey('page', newPage);
    },
    [fetchWalletsPayment, perPage, setOptionByKey],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, newPage) => {
      await fetchWalletsPayment({
        perPage: newPerPage,
        page: newPage,
      });

      setOptionByKey('perPage', newPerPage);
      setOptionByKey('page', newPage);
    },
    [fetchWalletsPayment, setOptionByKey],
  );

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        selector: 'id',
        sortable: false,
        width: '60px',
      },
      {
        name: 'Dirección',
        selector: 'address',
        sortable: false,
        width: '450px',
      },
      {
        name: 'Red',
        selector: 'network',
        sortable: false,
      },
      {
        name: 'Moneda',
        selector: 'currency',
        sortable: false,
      },
      {
        name: 'Estado',
        selector: 'active',
        cell: (row) => (
          <SwitchActiveWallet
            active={row.active}
            walletId={row.id}
            refetchWallets={() => fetchWalletsPayment({ page, perPage })}
          />
        ),
        sortable: false,
      },
    ],
    [fetchWalletsPayment, page, perPage],
  );

  const filtersComponent = useMemo(() => {
    return (
      <Box marginTop={2} marginBottom={2}>
        <Typography>
          Activas: {countDataState.actives} | No activas:{' '}
          {countDataState.noActives}
        </Typography>
      </Box>
    );
  }, [countDataState]);

  return (
    <TableList
      data={data}
      columns={columns}
      title="Listado de Wallets de pago"
      filtersComponent={filtersComponent}
      perPage={perPage}
      loading={loadingFetch}
      totalRows={totalRows}
      handlePerRowsChange={handlePerRowsChange}
      handlePageChange={handlePageChange}
      classCardContainer={classNames(classes.cardTableContainer, {
        [classes.gutterTop]: gutterTop,
      })}
    />
  );
};

TableWalletsPayment.propTypes = {
  currencyNetwork: PropTypes.string.isRequired,
  gutterTop: PropTypes.bool,
};

TableWalletsPayment.defaultProps = {
  gutterTop: true,
};

export default TableWalletsPayment;
