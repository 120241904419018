import axiosInstance from './axiosInstance';

const getDevolutions = (filters = {}) => {
  const {
    perPage,
    page,
    column = 'created_at',
    orderColumn = 'DESC',
  } = filters;

  return axiosInstance.get('/internal/admin/refunds', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: orderColumn,
    },
  });
};

export default getDevolutions;
