import Input from '../Input';
import Select from '../Select';
import CryptoCurrencies from '../CryptoCurrencies';
import DatepickerRange from '../DatepickerRange';

const ComponentList = {
  Input,
  Select,
  CryptoCurrencies,
  DatepickerRange,
};

export const getComponent = (name) => ComponentList[name] || null;
