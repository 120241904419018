import { drawerWidthClose } from '../LateralMenu/styles';

export default (theme) => ({
  wrapped: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: drawerWidthClose,
    marginTop: 56,
    minHeight: 'calc(100vh - 56px)',
    width: '100%',
    maxWidth: `calc(100% - ${drawerWidthClose}px)`,

    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: 48,
      minHeight: 'calc(100vh - 48px)',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
      minHeight: 'calc(100vh - 64px)',
    },
  },
});
