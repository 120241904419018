import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  Visibility as ShowIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { startsWith } from 'lodash';

import styles from './styles';

const useStyles = makeStyles(styles);

const MenuActionsTable = ({
  idItem,
  resourcePath,
  extraData,
  options = {},
}) => {
  const classes = useStyles();
  const { view = true, edit = true, delet = true } = options;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pathShowItem = startsWith(resourcePath, '/')
    ? `${resourcePath}/${idItem}`
    : `/${resourcePath}/${idItem}`;

  return (
    <Box>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuPaper }}
      >
        {view && (
          <MenuItem
            component={Link}
            to={{ pathname: pathShowItem, state: extraData }}
          >
            <ListItemIcon>
              <ShowIcon fontSize="small" className={classes.showIcon} />
            </ListItemIcon>
            <ListItemText primary="Ver" />
          </MenuItem>
        )}

        {edit && (
          <MenuItem
            component={Link}
            to={{ pathname: `${pathShowItem}/edit`, state: extraData }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" className={classes.editIcon} />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}

        {delet && (
          <MenuItem>
            <ListItemIcon>
              <DeleteIcon fontSize="small" className={classes.deleteIcon} />
            </ListItemIcon>
            <ListItemText primary="Eliminar" />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

MenuActionsTable.propTypes = {
  idItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  resourcePath: PropTypes.string.isRequired,
  extraData: PropTypes.instanceOf(Object),
  options: PropTypes.shape({
    view: PropTypes.bool,
    edit: PropTypes.bool,
    delet: PropTypes.bool,
  }).isRequired,
};

MenuActionsTable.defaultProps = {
  extraData: undefined,
};

export default MenuActionsTable;
