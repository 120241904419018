import axiosInstance from './axiosInstance';

const getReports = (filters = {}) => {
  const { pageNumber, pageSize } = filters;

  return axiosInstance.get('/internal/admin/reports', {
    params: {
      per_page: pageSize,
      page: pageNumber,
    },
  });
};

export default getReports;
