// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetKriptoBankById
 * @property {Object} data - Objeto con los datos del banco
 */

/**
 * Obtener datos del banco segun su ID
 * @param {String | Number} bankId - Id del banco a buscar
 * @returns {Promise<AxiosResponse<ResponseGetKriptoBankById>>} Informacion del kriptopayment
 */
const getBankById = (bankId) =>
  axiosInstance.get(`/internal/admin/config/banks/${bankId}`);

export default getBankById;
