import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';

import { useRequest } from '../../hooks';
import { getPromotions } from '../../api';
import Input from '../Input';
import Select from '../Select';
import CryptoCurrencies from '../CryptoCurrencies';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { getHelperTextField, hasError } from '../../utils';

import { initialValues, validationSchema, listOfResources } from './utils';

const RewardsForm = ({ onSubmit }) => {
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const [promotions, loadingPromotions] = useRequest(
    {
      initialState: [],
      request: getPromotions,
      payload: { active: true, manual: true },
      transformResponse: (response) => {
        const parsePromotions = response.data.map((promotion) => ({
          label: promotion.name,
          value: String(promotion.id),
        }));

        return parsePromotions;
      },
    },
    [],
  );

  return (
    <Grid item container spacing={2} component="form" onSubmit={handleSubmit}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            Datos del reward
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Input
            size="small"
            variant="outlined"
            fullWidth
            autoComplete="off"
            label="ID del recurso"
            error={hasError(errors, touched, 'resourceId')}
            helperText={getHelperTextField(errors, touched, 'resourceId')}
            {...getFieldProps('resourceId')}
          />
        </Grid>

        <Grid item xs={4}>
          <Select
            size="small"
            variant="outlined"
            fullWidth
            autoComplete="off"
            label="Recurso"
            error={hasError(errors, touched, 'resource')}
            helperText={getHelperTextField(errors, touched, 'resource')}
            {...getFieldProps('resource')}
            data={listOfResources}
          />
        </Grid>

        <Grid item xs={4}>
          <Input
            size="small"
            variant="outlined"
            fullWidth
            autoComplete="off"
            label="Monto"
            error={hasError(errors, touched, 'amount')}
            helperText={getHelperTextField(errors, touched, 'amount')}
            {...getFieldProps('amount')}
          />
        </Grid>

        <Grid item xs={4}>
          <CryptoCurrencies
            size="small"
            variant="outlined"
            fullWidth
            autoComplete="off"
            error={hasError(errors, touched, 'currency')}
            helperText={getHelperTextField(errors, touched, 'currency')}
            {...getFieldProps('currency')}
          />
        </Grid>

        <Grid item xs={4}>
          <Select
            size="small"
            variant="outlined"
            fullWidth
            autoComplete="off"
            label="Promoción"
            error={hasError(errors, touched, 'promotion')}
            helperText={getHelperTextField(errors, touched, 'promotion')}
            {...getFieldProps('promotion')}
            data={promotions}
            loading={loadingPromotions}
          />
        </Grid>
      </Grid>

      <Grid item container spacing={3} justify="center">
        <Grid item xs="auto">
          <ButtonWithCirculaProgress
            type="submit"
            loading={isSubmitting}
            disabled={!dirty}
            label="Guardar"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

RewardsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RewardsForm;
