import { useState, useCallback } from 'react';

/**
 * @typedef {Object} ObjectReturnUseShowPassword
 * @property {Boolean} showPassword - Boolean para comprobar que se puede mostrar la contraseña
 * @property {() => void} toggleShowPassword - Funcion para ejecutar el cambio
 */

/**
 * Hook para mostrar la contraseña para un input
 * @returns {ObjectReturnUseShowPassword}
 */
export const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return { showPassword, toggleShowPassword };
};
