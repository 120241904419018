import axiosInstance from './axiosInstance';

const updateDevolution = ({ id, thash = null, file = null, status = null }) => {
  const form = new FormData();
  form.append('file', file);
  form.append('new_state', status);
  form.append('tx_hash', thash);
  return axiosInstance.patch(`/internal/admin/refunds/${id}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default updateDevolution;
