import { useState, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { isEqual, isFunction } from 'lodash';

import { useRequest } from './useRequest';

export const useDataTableRequest = ({
  request,
  payload,
  transformResponse,
}) => {
  const [queryParams, setQueryParamsBase] = useState(payload);

  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        // eslint-disable-next-line no-param-reassign
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const [, loading, error, sendRequest] = useRequest(
    {
      initialState: [],
      request,
      payload: queryParams,
      transformResponse,
      withPostSuccess: (response) => {
        const { data: dataResponse, total_elements: totalElements } = response;

        setData(dataResponse);
        setCount(totalElements);
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'Lo sentimos lo datos no pudieron ser cargados, intententelo nuevamente mas tarde',
          { variant: 'error' },
        );
      },
    },
    [queryParams],
  );

  const handlePageChange = useCallback(
    (newPage) => {
      setQueryParams((prev) => ({ ...prev, pageNumber: newPage }));
    },
    [setQueryParams],
  );

  const handlePerRowsChange = useCallback(
    (newPerPage, newPage) => {
      setQueryParams((prev) => ({
        ...prev,
        pageNumber: newPage,
        pageSize: newPerPage,
      }));
    },
    [setQueryParams],
  );

  const refetchData = useCallback(() => sendRequest(queryParams), [
    queryParams,
    sendRequest,
  ]);

  const actions = useMemo(
    () => ({
      handlePageChange,
      handlePerRowsChange,
      refetchData,
    }),
    [handlePageChange, handlePerRowsChange, refetchData],
  );

  return [loading, data, count, error, queryParams, actions];
};
