// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetOperations
 * @property {Array.<Object>} data - Lista de operaciones
 * @property {Number} current_page - Pagina Actual
 * @property {Number} per_page - Cantidad items por pagina
 * @property {Number} total_pages - Total de paginas
 * @property {Number} total_elements - Total de Items
 */

/**
 * @typedef StatusFilter
 * @type {'request' | 'wait' | 'received' | 'complete' | 'cancel' | 'pending_by_validate'}
 */

/**
 * @typedef {Object} FiltersObjectGetOperations
 * @property {Number} [perPage] - Cantidad items por pagina
 * @property {Number} [page] - Pagina Actual
 * @property {'created_at'} [column] - Orden por columna
 * @property {'ASC' | 'DESC'} [orderColumn] - Orden por sentido
 * @property {'cash-in' | 'cash-out' | 'swap'} [type] - Tipo de operacion
 * @property {Number | String} [merchantId] - Buscar por un merchant
 * @property {Number | String} [clientId] - Buscar por un cliente
 * @property {StatusFilter} [status] - Buscar por estado
 */

/**
 * Obtener las lista de operaciones
 * @param {FiltersObjectGetOperations} [filters] - Filtros
 * @returns {Promise<AxiosResponse<ResponseGetOperations>>} Listado de usuarios
 */
const getOperations = (filters = {}) => {
  const {
    perPage,
    page,
    column = 'created_at',
    orderColumn = 'DESC',
    type,
    merchantId,
    clientId,
    status,
  } = filters;

  return axiosInstance.get('/internal/admin/operations', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: orderColumn,
      type,
      merchant_id: merchantId,
      client_id: clientId,
      state: status,
    },
  });
};

export default getOperations;
