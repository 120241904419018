// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} DataParamsUploadVoucherKriptoPayment
 * @property {String | Number} id - Id del kriptopayment a subir el voucher
 * @property {File} voucher - Voucher
 */

/**
 * Subir un voucher a kriptopayment
 * @param {DataParamsUploadVoucherKriptoPayment} data
 * @returns {Promise<AxiosResponse>}
 */
const uploadVoucherKriptoPayment = (data) => {
  const { id, voucher } = data;

  const form = new FormData();
  form.append('file', voucher);

  return axiosInstance.patch(
    `/internal/admin/kriptopayments/${id}/upload_voucher`,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export default uploadVoucherKriptoPayment;
