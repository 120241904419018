import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Button, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import styles from './styles';

const useStyles = makeStyles(styles);

const ButtonWithCirculaProgress = ({
  handleButtonClick,
  loading,
  disabled,
  label,
  type,
  color,
  variant,
  gutters,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classNames(classes.wrapper, { [classes.gutters]: gutters })}
    >
      <Button
        type={type}
        color={isEqual(color, 'success') ? 'inherit' : color}
        variant={variant}
        disabled={loading || disabled}
        onClick={handleButtonClick}
        classes={{
          text: classNames({
            [classes.textSuccess]: isEqual(color, 'success'),
          }),
          outlined: classNames({
            [classes.outlinedSuccess]: isEqual(color, 'success'),
          }),
          contained: classNames({
            [classes.containedSuccess]: isEqual(color, 'success'),
          }),
        }}
        {...rest}
      >
        {label}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classes.buttonProgress}
          color={
            isEqual(color, 'success') || isEqual(color, 'default')
              ? 'inherit'
              : color
          }
          classes={{
            root: classNames({
              [classes.progressSuccess]: isEqual(color, 'success'),
            }),
          }}
        />
      )}
    </Box>
  );
};

ButtonWithCirculaProgress.propTypes = {
  handleButtonClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  gutters: PropTypes.bool,
};

ButtonWithCirculaProgress.defaultProps = {
  handleButtonClick: () => {},
  disabled: false,
  type: 'button',
  color: 'primary',
  variant: 'contained',
  gutters: true,
};

export default ButtonWithCirculaProgress;
