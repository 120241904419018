import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import TableList from '../TableList';

const HistoryPayments = ({ history, criptoCurrency }) => {
  const columns = useMemo(
    () => [
      {
        name: 'Monto Pagado',
        selector: 'amount_paid',
        format: (row) => {
          return `${criptoCurrency} ${row.amount_paid}`;
        },
        sortable: false,
      },
      {
        name: 'Deuda',
        selector: 'amount',
        format: (row) => {
          return `${criptoCurrency} ${row.amount}`;
        },
        sortable: false,
      },
      {
        name: 'Pagado desde',
        selector: 'from',
        sortable: false,
      },
    ],
    [criptoCurrency],
  );

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <TableList data={history} columns={columns} title="Otros pagos" />
      </Grid>
    </Grid>
  );
};

HistoryPayments.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      amount_paid: PropTypes.string,
      from: PropTypes.string,
    }),
  ),
  criptoCurrency: PropTypes.string,
};

HistoryPayments.defaultProps = {
  history: [],
  criptoCurrency: '',
};

export default HistoryPayments;
