import { get, isEqual, isEmpty } from 'lodash';

/**
 * Obtiene el objeto con la informacion correspondiente para cada usuario
 * @param {Object} obj - Objeto de busqueda
 * @returns {Object} Retorna informacion correspondiente al tipo de usuario
 */
export const getDataByClientType = (obj) => {
  const findDataPersonal = get(obj, 'personal_information', {});
  const hasDataPersonal = !isEmpty(findDataPersonal);

  return hasDataPersonal
    ? findDataPersonal
    : get(obj, 'fiscal_information', {});
};

/**
 * @typedef MaritalStatusList
 * @type {'single' | 'married' | 'divorcee' | 'widower'}
 */

/**
 * Obtiene la descripcion segun el estado civil del cliente
 * @param {MaritalStatusList} maritalStatus - Estado civil
 * @returns {String} Retorna la descripcion segun el estado civil
 */
export const getMaritalStatusDescripcion = (maritalStatus) => {
  let description = '';

  if (isEqual(maritalStatus, 'single')) description = 'Soltero/a';
  if (isEqual(maritalStatus, 'married')) description = 'Casado/a';
  if (isEqual(maritalStatus, 'divorcee')) description = 'Divorciado/a';
  if (isEqual(maritalStatus, 'widower')) description = 'Viudo/a';

  return description;
};

export const getClientName = (client) => {
  let currentName = '';

  if (client.first_name || client.last_name) {
    currentName = `${client.first_name} ${client.last_name}`;
  } else if (client.commercial_name) {
    currentName = client.commercial_name;
  }

  return currentName;
};
