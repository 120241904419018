// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetListCurrencies
 * @property {Array.<Object>} data - Listado de divisas
 */

/**
 * Obtener la lista de divisas
 * @returns {Promise<AxiosResponse<ResponseGetListCurrencies>>} Listado de divisas
 */
const getCurrencies = () => axiosInstance.get('/public/currencies');

export default getCurrencies;
