import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Badge } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigIndexersBadge = ({ current, children }) => {
  const classes = useStyles();

  if (!current) return children;

  return (
    <Badge
      color="primary"
      badgeContent="Default"
      overlap="rectangle"
      className={classes.badge}
    >
      {children}
    </Badge>
  );
};

ConfigIndexersBadge.propTypes = {
  current: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConfigIndexersBadge;
