import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormControlLabel, Switch } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '../../hooks';
import { updateActiveIndexerById } from '../../api';

import ConfigIndexersBadge from './Badge';
import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigIndexersSwitch = ({
  id,
  mainName,
  cryptoNetWorkName,
  currencyName,
  name,
  current,
  active,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [isActive, setIsActive] = useState(active);

  const completeName = useMemo(
    () => `${mainName} -> ${cryptoNetWorkName} -> ${currencyName} -> ${name}`,
    [cryptoNetWorkName, currencyName, mainName, name],
  );

  const [, , , executeUpdateActive] = useLazyRequest({
    request: updateActiveIndexerById,
    withPostSuccess: () => {
      const messageSuccess = `${completeName} se  ${
        isActive ? 'desactivo' : 'activo'
      } satisfactoriamente`;

      enqueueSnackbar(messageSuccess, { variant: 'success' });

      setTimeout(() => {
        setIsActive(!isActive);
      }, 100);
    },
    withPostFailure: () => {
      const errorMessage = `No se pudo ${
        isActive ? 'desactivar' : 'activar'
      } ${completeName}, intententelo nuevamente mas tarde`;

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleIsActive = useCallback(
    (event) => {
      event.preventDefault();
      const currrentAllow = event.target.checked;

      executeUpdateActive({ id, active: currrentAllow });
    },
    [executeUpdateActive, id],
  );

  return (
    <ConfigIndexersBadge current={current}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={isActive}
            onChange={handleIsActive}
            size="small"
          />
        }
        label={name}
        className={classes.formControLabel}
      />
    </ConfigIndexersBadge>
  );
};

ConfigIndexersSwitch.propTypes = {
  mainName: PropTypes.string.isRequired,
  cryptoNetWorkName: PropTypes.string.isRequired,
  currencyName: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  current: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default ConfigIndexersSwitch;
