// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetRetirements
 * @property {Array.<Object>} data - Lista de operaciones
 * @property {Number} current_page - Pagina Actual
 * @property {Number} per_page - Cantidad items por pagina
 * @property {Number} total_pages - Total de paginas
 * @property {Number} total_elements - Total de Items
 */

/**
 * @typedef {Object} FiltersObjectGetRetirements
 * @property {Number} [perPage] - Cantidad items por pagina
 * @property {Number} [page] - Pagina Actual
 * @property {'created_at} [column] - Orden por columna
 * @property {'ASC' | 'DESC'} [orderColumn] - Orden por sentido
 * @property {String} paymentMethodType - Buscar por tipo de metodo de pago
 * @property {String | Number} merchantId - Buscar por un merchant
 */

/**
 * Obtener las lista de retiros
 * @param {FiltersObjectGetRetirements} [filters] - Filtros
 * @returns {Promise<AxiosResponse<ResponseGetRetirements>>} Listado de retiros
 */
const getRetirements = (filters = {}) => {
  const {
    perPage,
    page,
    column = 'created_at',
    orderColumn = 'DESC',
    paymentMethodType,
    merchantId,
  } = filters;

  return axiosInstance.get('/internal/admin/retirements', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: orderColumn,
      payment_method_type: paymentMethodType,
      merchant_id: merchantId,
    },
  });
};

export default getRetirements;
