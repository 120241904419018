import { forEach } from 'lodash';
import axiosInstance from './axiosInstance';

const createSlide = (data) => {
  const form = new FormData();
  forEach(data, (value, key) => {
    form.append(key, value);
  });

  return axiosInstance.post('/internal/admin/config/carousels', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default createSlide;
