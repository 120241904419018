// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetOperationById
 * @property {Object} data - Objeto con los datos de la operacion
 */

/**
 * Obtener datos de una operacion segun su ID
 * @param {String | Number} operationtId - Id del cliente a buscar
 * @returns {Promise<AxiosResponse<ResponseGetOperationById>>} Informacion de la operacion
 */
const getOperationById = (operationtId) =>
  axiosInstance.get(`/internal/admin/operations/${operationtId}`);

export default getOperationById;
