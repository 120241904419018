// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} DataParamsUpdateOperationSwap
 * @property {String} reference_2 - Referencia o soporte (puede ser un tx hash, texto, etc.)
 */

/**
 * Actualizar los datos de una operacion Swap
 * @param {String | Number} swapId - Id del Swap a buscar
 * @param {DataParamsUpdateOperationSwap} data - Datos a actualizar
 * @returns {Promise<AxiosResponse>}
 */
const updateOperationSwap = (swapId, data) =>
  axiosInstance.patch(`/internal/admin/swaps/${swapId}`, data);

export default updateOperationSwap;
