/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
import { useState, useCallback, useEffect } from 'react';
import getBeneficiaries, {
  TypeBeneficiaries,
  BeneficiarieItem,
} from '../api/getBeneficiaries';

/**
 * Hook para obtener las lista de beneficiarios segun el tipo de recurso
 * @param {TypeBeneficiaries} type Tipo de recursos a buscar
 * @returns {Array.<BeneficiarieItem>} Listado de Beneficarios
 */
export const useFetchBeneficiaries = (type = 'clients') => {
  const [listBeneficiaries, setListBeneficiaries] = useState([]);

  const fetchBeneficiaries = useCallback(async () => {
    try {
      const { data: dataResponse } = await getBeneficiaries(type);
      setListBeneficiaries(dataResponse.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBeneficiaries();
  }, [fetchBeneficiaries]);

  return listBeneficiaries;
};
