import { string, object, date } from 'yup';
import { chain, isEmpty } from 'lodash';

import { typesInputs as typesRangeDate } from '../DatepickerRange/utils';
import { tranformDateForm } from '../../utils/helpers';

export const initialValues = {
  type: '',
  options: {},
};

export const getOptiosFiltersValues = (filters) =>
  filters.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.name_of_param]:
        filter.name_of_param === 'range'
          ? {
              [typesRangeDate.startDate]: null,
              [typesRangeDate.endDate]: null,
            }
          : '',
    }),
    {},
  );

const validationTypes = {
  CryptoCurrencies: string(),
  DatepickerRange: object().shape({
    [typesRangeDate.startDate]: date()
      .transform(tranformDateForm('DD/MM/YYYY hh:mm A'))
      .nullable()
      .typeError('Fecha Invalida')
      .required('Campo requerido'),
    [typesRangeDate.endDate]: date()
      .transform(tranformDateForm('DD/MM/YYYY hh:mm A'))
      .nullable()
      .typeError('Fecha Invalida')
      .required('Campo requerido'),
  }),
  Select: string(),
  Input: string(),
};

const buildOptionsFiltersValidationsSchema = (filters) => {
  const validations = filters.reduce(
    (obj, filter) => ({
      ...obj,
      [filter.name_of_param]: filter.required
        ? validationTypes[filter.type].required('Campo requerido')
        : validationTypes[filter.type],
    }),
    {},
  );

  return validations;
};

export const getFilters = (config, typeReport) =>
  chain(config).find({ value: typeReport }).get('filters', []).value();

export const validationSchema = (configReports) =>
  object({
    type: string().required('Campo requerido'),
    options: object().when('type', (valType, schema) =>
      isEmpty(valType)
        ? schema.notRequired()
        : schema.shape(
            buildOptionsFiltersValidationsSchema(
              getFilters(configReports, valType),
            ),
          ),
    ),
  });
