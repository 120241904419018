const existHeight = (condition, height) => (condition ? 0 : height);

export default () => ({
  cardContainer: {
    height: '100%',
  },
  tableContainer: {
    height: ({
      noHeader,
      minHeightHeader,
      noSubHeader,
      minHeightSubHeader,
      noPagination,
      minHeightPagination,
    }) =>
      `calc(100% - ${
        existHeight(noHeader, minHeightHeader) +
        existHeight(noSubHeader, minHeightSubHeader) +
        existHeight(noPagination, minHeightPagination)
      }px)`,
  },
});
