import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { formatDate } from '../../utils/helpers';

const defaultValDate = '-------';

const DashboardInfo = ({ info }) => {
  const parseDate = useCallback(
    (date) =>
      !!info.transactions && !!date
        ? formatDate(date, 'DD/MM/YYYY hh:mm A')
        : defaultValDate,
    [info.transactions],
  );

  const fromVal = useMemo(() => parseDate(info.from), [parseDate, info.from]);

  const toVal = useMemo(() => parseDate(info.to), [parseDate, info.to]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Información</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1">
          <b>Transacciones:</b> {info.transactions} <b>Desde:</b> {fromVal}{' '}
          <b>Hasta:</b> {toVal}
        </Typography>
      </Grid>
    </Grid>
  );
};

DashboardInfo.propTypes = {
  info: PropTypes.shape({
    transactions: PropTypes.number.isRequired,
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

export default DashboardInfo;
