import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { useToggleModal, useLazyRequest } from '../../hooks';
import { forceCompletedPayment } from '../../api';
import { getHelperTextField, hasError } from '../../utils';

import Input from '../Input';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { initialValues, validationSchema } from './utils';

const ForceCompletedPaymentButton = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { open: openDialog, toggleOpen: toggleOpenDialog } = useToggleModal();

  const [
    ,
    loadingForceCompletedPayment,
    ,
    executeForceCompletePayment,
  ] = useLazyRequest({
    request: forceCompletedPayment,
    withPostSuccess: () => {
      enqueueSnackbar('El pago fue completado correctamente', {
        variant: 'success',
      });

      history.push('/payments');
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'Ha ocurrido un error, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const onForceCompletedPayment = useCallback(
    (values) => {
      executeForceCompletePayment({ id, txHash: values.txHash || undefined });
    },
    [executeForceCompletePayment, id],
  );

  const { errors, touched, getFieldProps, submitForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onForceCompletedPayment,
  });

  return (
    <>
      <Button variant="contained" color="default" onClick={toggleOpenDialog}>
        Forzar Completado
      </Button>

      <Dialog fullWidth maxWidth="xs" open={openDialog}>
        <DialogTitle>Forzar Completado</DialogTitle>

        <DialogContent>
          <Input
            name="txHash"
            label="Tx-Hash (Opcional)"
            error={hasError(errors, touched, 'txHash')}
            helperText={getHelperTextField(errors, touched, 'txHash')}
            fullWidth
            size="small"
            autoComplete="off"
            {...getFieldProps('txHash')}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => toggleOpenDialog()}
            color="default"
            variant="outlined"
          >
            Cancelar
          </Button>

          <ButtonWithCirculaProgress
            variant="outlined"
            color="primary"
            label="Confirmar"
            gutters={false}
            handleButtonClick={submitForm}
            loading={loadingForceCompletedPayment}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

ForceCompletedPaymentButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ForceCompletedPaymentButton;
