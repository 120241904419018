import { useState, useCallback } from 'react';

/**
 * @typedef {Object} ObjectParamInitialOptions
 * @property {Boolean} loading - Si la data esta cargando
 * @property {Array} data - Items para el data table
 * @property {Number} totalRows - Total de items
 * @property {Number} perPage - Items por pagina
 */

/**
 * @callback FunctionSetOptions
 * @param {ObjectParamInitialOptions}
 */

/**
 * @callback FunctionSetOptionByKey
 * @param {String} optionKey - Key de la opcion a modificar
 * @param {String | Number} newValue - Valor a modificar
 */

/**
 * @typedef {Object} ObjectReturnUseDataTableOptions
 * @property {ObjectParamInitialOptions} options - Opciones
 * @property {FunctionSetOptions} setOptions - Modificar opciones
 * @property {FunctionSetOptionByKey} setOptionByKey - Modficar una opcion segun la key
 */

/**
 * Hook para obtener las opciones del Data Table
 * @param {ObjectParamInitialOptions} initialOptions - Valores iniciales
 * @returns {ObjectReturnUseDataTableOptions}
 */
export const useDataTableOptions = (
  initialOptions = {
    loading: true,
    data: [],
    totalRows: 0,
    perPage: 25,
  },
) => {
  const [options, setOptions] = useState(initialOptions);

  const setOptionByKey = useCallback((optionKey, newValue) => {
    setOptions((prevValue) => ({ ...prevValue, [optionKey]: newValue }));
  }, []);

  return { options, setOptions, setOptionByKey };
};
