import { get } from 'lodash';

export const statusList = {
  request: 'request',
  wait: 'wait',
  received: 'received',
  complete: 'complete',
  cancel: 'cancel',
  pending_by_validate: 'pending_by_validate',
  to_claim: 'to_claim',
  claimed: 'claimed',
  refund: 'refund',
  confirm: 'confirm',
  fail: 'fail',
  liquidating: 'liquidating',
  buying: 'buying',
  paying: 'paying',
  shipping: 'shipping',
};

export const typesOperation = {
  'cash-in': 'cash-in',
  'cash-out': 'cash-out',
  swap: 'swap',
  kriptostore: 'kriptostore',
  kriptopayment: 'kriptopayment',
  reward: 'reward',
};

const descriptionsList = {
  [statusList.request]: {
    [typesOperation['cash-in']]: 'Solicitada',
    [typesOperation['cash-out']]: 'Solicitada',
    [typesOperation.swap]: 'Solicitada',
  },
  [statusList.wait]: {
    [typesOperation['cash-in']]: 'Espera de pago',
    [typesOperation['cash-out']]: 'Espera TX',
    [typesOperation.swap]: 'Espera TX',
    [typesOperation.kriptostore]: 'En espera',
    [typesOperation.kriptopayment]: 'En espera',
  },
  [statusList.received]: {
    [typesOperation['cash-in']]: 'Pago realizado (Revisar banco)',
    [typesOperation['cash-out']]: 'Pago realizado (Revisar TX)',
    [typesOperation.swap]: 'Pago realizado',
    [typesOperation.kriptostore]: 'Procesando',
    [typesOperation.kriptopayment]: 'Procesando',
  },
  [statusList.complete]: {
    [typesOperation['cash-in']]: 'Completada',
    [typesOperation['cash-out']]: 'Completada',
    [typesOperation.swap]: 'Completada',
    [typesOperation.kriptostore]: 'Completando',
    [typesOperation.kriptopayment]: 'Completando',
  },
  [statusList.cancel]: {
    [typesOperation['cash-in']]: 'Cancelada',
    [typesOperation['cash-out']]: 'Cancelada',
    [typesOperation.swap]: 'Cancelada',
    [typesOperation.kriptostore]: 'Cancelado',
    [typesOperation.kriptopayment]: 'Cancelado',
    [typesOperation.reward]: 'Cancelado',
  },
  [statusList.pending_by_validate]: {
    [typesOperation['cash-in']]: 'Pendiente por validar',
    [typesOperation['cash-out']]: 'Pendiente por validar',
    [typesOperation.swap]: 'Pendiente por validar',
  },
  [statusList.to_claim]: {
    [typesOperation.reward]: 'Por reclamar',
  },
  [statusList.claimed]: {
    [typesOperation.reward]: 'Reclamada',
  },
  [statusList.refund]: {
    [typesOperation['cash-in']]: 'Reembolso',
    [typesOperation['cash-out']]: 'Reembolso',
    [typesOperation.swap]: 'Reembolso',
    [typesOperation.kriptostore]: 'Reembolso',
    [typesOperation.kriptopayment]: 'Reembolso',
  },
  [statusList.confirm]: {
    [typesOperation['cash-in']]: 'Pago recibido',
    [typesOperation['cash-out']]: 'Pago recibido',
    [typesOperation.swap]: 'Pago recibido',
  },
  [statusList.fail]: {
    [typesOperation['cash-out']]: 'TX entrante fallida',
  },
  [statusList.buying]: {
    [typesOperation.kriptostore]: 'En proceso de compra',
  },
  [statusList.paying]: {
    [typesOperation.kriptopayment]: 'En proceso de pago',
  },
  [statusList.shipping]: {
    [typesOperation.kriptostore]: 'Enviados',
  },
};

/**
 * @typedef StatusFilter
 * @type {'request' | 'wait' | 'received' | 'complete' | 'cancel' | 'pending_by_validate'}
 */

/**
 * @typedef TypeOperationFilter
 * @type {'cash-in' | 'cash-out' | 'swap' | 'kriptostore' | 'kriptopayment'}
 */

/**
 * Obtiene la descripcion del estado segun la operacion
 * @param {StatusFilter} status - Estado
 * @param {TypeOperationFilter} typeOperation - Tipo de operacion
 * @returns {String} Retorna la descripcion del estado
 */
export const getStatusDescription = (status, typeOperation) =>
  get(descriptionsList, [status, typeOperation], '');
