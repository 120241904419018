/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CashIn = (props) => (
  <SvgIcon viewBox="0 0 482.085 482.085" id="Capa_1" {...props}>
    <g>
      <path
        d="M461.271,343.312c-6.339-5.654-14.297-8.26-22.403-7.329c-13.612,1.557-31.436,5.233-50.307,9.125
        c-25.482,5.256-51.83,10.69-72.004,11.951c-17.426,1.086-49.161-3.454-58.563-8.379c-6.342-3.322-9.89-7.396-11.877-10.998h39.11
        c16.135,0,29.261-13.126,29.261-29.26v-22.978c0-16.134-13.126-29.259-29.261-29.259H178.02c-4.555-0.14-59.253-1.17-97.131,31.14
        c-6.704,5.718-12.036,11.121-16.288,16.031c-4.498-5.621-11.41-9.232-19.153-9.232H10.5c-5.799,0-10.5,4.701-10.5,10.5V452.96
        c0,2.785,1.107,5.456,3.075,7.425c1.97,1.969,4.641,3.075,7.425,3.075l34.947-0.001c13.525,0,24.528-11.003,24.528-24.527v-8.07
        c10.451,0.656,34.136,3.242,84.261,12.653c59.194,11.115,96.13,14.882,125.231,14.881c20.856,0,37.691-1.934,55.801-4.485
        c22.387-3.151,50.544-11.116,75.386-18.143c11.5-3.253,22.362-6.325,31.974-8.749c25.901-6.533,29.769-22.865,29.769-32.02V368.25
        C472.397,358.85,468.237,349.527,461.271,343.312z M48.976,438.932c0,1.945-1.583,3.527-3.528,3.527L21,442.46V315.125h24.447
        c1.945,0,3.528,1.582,3.528,3.527V438.932z M451.397,395c0,2.23,0,8.15-13.903,11.656c-9.903,2.498-20.906,5.61-32.555,8.905
        c-24.198,6.845-51.625,14.603-72.598,17.555c-41.317,5.818-75.766,8.248-174.23-10.239c-50.639-9.508-75.773-12.382-88.136-13.041
        v-77.961c0.375-0.469,0.722-0.968,1.021-1.513c2.69-4.908,9.323-14.949,23.521-27.059c32.373-27.613,82.42-26.142,82.913-26.125
        c0.136,0.005,0.272,0.008,0.408,0.008h107.39c4.555,0,8.261,3.705,8.261,8.259v22.978c0,4.555-3.706,8.26-8.261,8.26h-107.39
        c-5.799,0-10.5,4.701-10.5,10.5s4.701,10.5,10.5,10.5h45.977c2.159,8.61,8.116,21.052,24.435,29.6
        c14.925,7.819,51.944,11.846,69.616,10.736c21.654-1.352,48.742-6.939,74.938-12.343c18.396-3.795,35.772-7.379,48.451-8.829
        c2.672-0.302,4.756,0.993,6.037,2.137c2.532,2.259,4.106,5.811,4.106,9.268V395z"
      />
      <path
        d="M455.157,18.625H86.362c-14.848,0-26.928,12.08-26.928,26.928V218.45c0,14.849,12.08,26.93,26.928,26.93h368.795
        c14.848,0,26.928-12.081,26.928-26.93V45.552C482.085,30.705,470.005,18.625,455.157,18.625z M403.067,224.379H138.474
        c-2.782-31.306-27.122-56.49-58.039-60.583V97.343c29.967-3.968,53.751-27.752,57.718-57.719h265.235
        c3.967,29.961,27.74,53.741,57.697,57.717v66.457C430.179,167.9,405.849,193.081,403.067,224.379z M461.085,45.552v30.528
        c-18.359-3.591-32.854-18.093-36.438-36.456h30.51C458.426,39.625,461.085,42.284,461.085,45.552z M86.362,39.625h30.531
        c-3.584,18.37-18.089,32.875-36.459,36.46V45.552C80.435,42.284,83.094,39.625,86.362,39.625z M80.435,218.45v-33.395
        c19.314,3.769,34.352,19.611,36.919,39.324H86.362C83.094,224.379,80.435,221.719,80.435,218.45z M455.157,224.379h-30.97
        c2.566-19.706,17.593-35.545,36.897-39.32v33.391C461.085,221.719,458.426,224.379,455.157,224.379z"
      />
      <path
        d="M271.325,56.884c-41.421,0-75.119,33.697-75.119,75.117c0,41.421,33.698,75.119,75.119,75.119
        c41.42,0,75.118-33.698,75.118-75.119C346.443,90.582,312.745,56.884,271.325,56.884z M271.325,186.121
        c-29.842,0-54.119-24.277-54.119-54.119c0-29.84,24.277-54.117,54.119-54.117c29.841,0,54.118,24.277,54.118,54.117
        C325.443,161.843,301.166,186.121,271.325,186.121z"
      />
      <path
        d="M150.439,99.705c-17.81,0-32.298,14.488-32.298,32.298c0,17.809,14.488,32.297,32.298,32.297
        c17.809,0,32.297-14.488,32.297-32.297C182.736,114.193,168.248,99.705,150.439,99.705z M150.439,143.299
        c-6.229,0-11.298-5.067-11.298-11.297c0-6.23,5.068-11.298,11.298-11.298c6.23,0,11.297,5.068,11.297,11.298
        C161.736,138.232,156.669,143.299,150.439,143.299z"
      />
      <path
        d="M392.209,99.705c-17.809,0-32.297,14.488-32.297,32.298c0,17.809,14.488,32.297,32.297,32.297
        c17.81,0,32.299-14.488,32.299-32.297C424.508,114.193,410.019,99.705,392.209,99.705z M392.209,143.299
        c-6.23,0-11.297-5.067-11.297-11.297c0-6.23,5.067-11.298,11.297-11.298c6.23,0,11.299,5.068,11.299,11.298
        C403.508,138.232,398.44,143.299,392.209,143.299z"
      />
    </g>
  </SvgIcon>
);

export default CashIn;
