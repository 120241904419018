// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} DataParamsCreateQuotation
 * @property {Number} market_value - Precio de mercado
 * @property {Number} sell - Porcentaje de venta
 * @property {Number} buy - Porcentaje de compra
 * @property {Number} swap_sell - Porcentaje de swap compra
 * @property {Number} swap_buy - Porcentaje de compra
 * @property {String} currency_id - Id de la moneda asociada
 */

/**
 * Crear una cotizacion
 * @param {DataParamsCreateQuotation} data - Datos para crear
 * @returns {Promise<AxiosResponse>}
 */
const createQuotation = (data) =>
  axiosInstance.post('/internal/admin/quotations', data);

export default createQuotation;
