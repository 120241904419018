import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Button } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const FiltersTableWrapped = ({ children, handleFilter, handleClear }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.containerFilterInputs}>{children}</Box>
      <Box className={classes.containerActions}>
        <Button variant="contained" color="primary" onClick={handleFilter}>
          Aplicar
        </Button>
        <Button variant="outlined" color="primary" onClick={handleClear}>
          Borrar
        </Button>
      </Box>
    </Box>
  );
};

FiltersTableWrapped.propTypes = {
  children: PropTypes.node.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};

export default FiltersTableWrapped;
