// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetBanks
 * @property {Array.<Object>} data - Lista de Kriptopayments
 * @property {Number} current_page - Pagina Actual
 * @property {Number} per_page - Cantidad items por pagina
 * @property {Number} total_pages - Total de paginas
 * @property {Number} total_elements - Total de Items
 */

/**
 * @typedef {Object} FiltersObjectGetBanks
 * @property {Number} [perPage] - Cantidad de recursos por pagina
 * @property {Number} [page] - Pagina Actual
 * @property {'created_at'} [column] - Orden por columna
 * @property {'ASC' | 'DESC'} [orderColumn] - Orden por sentido
 */

/**
 * Obtener las lista de bancos
 * @param {FiltersObjectGetBanks} [filters] - Filtros
 * @returns {Promise<AxiosResponse<ResponseGetBanks>>} Listado de bancos
 */
const getBanks = (filters = {}) => {
  const {
    perPage,
    page,
    column = 'created_at',
    orderColumn = 'DESC',
  } = filters;

  return axiosInstance.get('/internal/admin/config/banks', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: orderColumn,
    },
  });
};

export default getBanks;
