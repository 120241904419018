export const minHeightHeader = 56;
export const minHeightSubHeader = 52;
export const minHeightPagination = 56;

const defaultStyles = {
  header: {
    style: {
      minHeight: `${minHeightHeader}px`,
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  subHeader: {
    style: {
      minHeight: `${minHeightSubHeader}px`,
    },
  },
  headCells: {
    style: {
      backgroundColor: '#dbdbdb',
      fontSize: 14,
      fontWeight: 700,
    },
  },
  tableWrapper: {
    style: {
      width: '97%',
      height: '100%',
      left: 24,
    },
  },
  pagination: {
    style: {
      justifyContent: 'center',
      minHeight: `${minHeightPagination}px`,
    },
  },
};

export default defaultStyles;
