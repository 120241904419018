// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';
// eslint-disable-next-line import/named, no-unused-vars
import { DataParamsUpdateBank } from './updateBank';

/**
 * Crear un banco
 * @param {DataParamsUpdateBank} data - Datos para crear
 * @returns {Promise<AxiosResponse>}
 */
const createDevolution = (data) =>
  axiosInstance.post('/internal/admin/refunds', data);

export default createDevolution;
