export default (theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  containerFilterInputs: {
    display: 'flex',

    '& > *': {
      width: 220,
    },
    '& :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  containerActions: {
    marginLeft: theme.spacing(2),

    '& :first-child': {
      marginRight: theme.spacing(1),
    },
  },
});
