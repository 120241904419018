import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import moment from 'moment';

import RangePicker from 'react-range-picker';

import CustomPlaceHolder from './CustomPlaceHolder';

import { formatDate } from '../../utils/helpers';
import { typesInputs } from './utils';

const DatepickerRange = ({ name, showTime, size }) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const parseDate = useCallback(
    (date) => {
      let currentFormat = 'DD/MM/YYYY';

      if (showTime) {
        currentFormat += ' hh:mm A';
      }

      return formatDate(date, currentFormat);
    },
    [showTime],
  );

  const evaluateDateValue = useCallback(
    (startDate, endDate) => {
      const range = {};

      if (startDate) range[typesInputs.startDate] = parseDate(startDate);

      if (endDate) range[typesInputs.endDate] = parseDate(endDate);

      return range;
    },
    [parseDate],
  );

  const handleChange = useCallback(
    (startDate, endDate) => {
      const vals = evaluateDateValue(startDate, endDate);

      setFieldValue(name, vals);
      setFieldTouched(name, {
        [typesInputs.startDate]: true,
        [typesInputs.endDate]: true,
      });
    },
    [evaluateDateValue, name, setFieldTouched, setFieldValue],
  );

  const defaultValue = useMemo(() => {
    const defaultVal = get(values, name);

    return {
      startDate: defaultVal[typesInputs.startDate]
        ? new Date(
            moment(defaultVal[typesInputs.startDate], 'DD/MM/YYYY').format(),
          )
        : null,
      endDate: defaultVal[typesInputs.endDate]
        ? new Date(
            moment(defaultVal[typesInputs.endDate], 'DD/MM/YYYY').format(),
          )
        : null,
    };
  }, [values, name]);

  return (
    <RangePicker
      defaultValue={defaultValue}
      placeholder={({ startDate, endDate }) => (
        <CustomPlaceHolder
          startDate={startDate ? parseDate(startDate) : ''}
          endDate={endDate ? parseDate(endDate) : ''}
          nameDateRange={name}
          size={size}
        />
      )}
      selectTime={showTime}
      onDateSelected={handleChange}
    />
  );
};

DatepickerRange.propTypes = {
  name: PropTypes.string.isRequired,
  showTime: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

DatepickerRange.defaultProps = {
  showTime: true,
  size: 'medium',
};

export default memo(DatepickerRange);
