import axiosInstance from './axiosInstance';

const updateLimits = ({
  userId,
  kriptoService,
  currency,
  minimunGeneral,
  maximumMonth,
  maximumYear,
}) =>
  axiosInstance.post('/internal/admin/config/limit', {
    user_id: userId,
    kripto_service_name: kriptoService,
    currency,
    minimun_general: minimunGeneral,
    maximum_month: maximumMonth,
    maximum_year: maximumYear,
    exception_max_limit: 0,
  });

export default updateLimits;
