import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import {
  AddCircleOutline as AddIcon,
  HighlightOff as RemoveIcon,
} from '@material-ui/icons';
import { Formik, Form, FieldArray } from 'formik';
import { object, string, array } from 'yup';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { getHelperTextField, hasError } from '../../utils';

const getInitialValues = (data) => ({
  name: data?.name ?? '',
  country: data?.country ?? '',
  currencyId: data?.currency_id ?? '',
  accountType: data?.account_type ?? '',
  accountNumber: data?.account_number ?? '',
  metadata: data?.metadata?.extras ?? [],
});

const validationSchema = object().shape({
  name: string().required('Campo Requerido'),
  country: string().required('Campo Requerido'),
  currencyId: string().required('Campo Requerido'),
  accountType: string().notRequired(),
  accountNumber: string().notRequired(),
  metadata: array()
    .of(
      object().shape({
        key: string().required('Campo Requerido'),
        value: string().required('Campo Requerido'),
      }),
    )
    .notRequired(),
});

const emptyMetadataObject = {
  key: '',
  value: '',
};

const BankForm = ({ data, onSubmit, countries, currencies }) => (
  <Formik
    initialValues={getInitialValues(data)}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      isSubmitting,
      dirty,
    }) => (
      <Grid item container component={Form} spacing={3}>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              Datos del banco
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="name"
              label="Nombre"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'name')}
              helperText={getHelperTextField(errors, touched, 'name')}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="country"
              label="Pais"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'country')}
              helperText={getHelperTextField(errors, touched, 'country')}
              select
            >
              <MenuItem value="" disabled>
                Selecciona...
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="currencyId"
              label="Moneda"
              value={values.currencyId}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'currencyId')}
              helperText={getHelperTextField(errors, touched, 'currencyId')}
              select
            >
              <MenuItem value="" disabled>
                Selecciona...
              </MenuItem>
              {currencies.map((currency) => (
                <MenuItem key={currency.value} value={currency.value}>
                  {currency.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="accountType"
              label="Tipo de cuenta"
              value={values.accountType}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'accountType')}
              helperText={getHelperTextField(errors, touched, 'accountType')}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="accountNumber"
              label="Numero de Cuenta"
              value={values.accountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'accountNumber')}
              helperText={getHelperTextField(errors, touched, 'accountNumber')}
            />
          </Grid>
        </Grid>

        <FieldArray name="metadata">
          {({ push, remove }) => (
            <Grid item container spacing={3}>
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs="auto">
                  <Typography variant="h5" color="primary">
                    Datos especificos
                  </Typography>
                </Grid>

                <Grid item xs="auto">
                  <IconButton
                    size="small"
                    onClick={() => push(emptyMetadataObject)}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {values.metadata.map((extra, index) => {
                const keyGlobal = `metadata[${index}]`;
                const keyMetadataKey = `${keyGlobal}.key`;
                const ketMetadataValue = `${keyGlobal}.value`;

                return (
                  <Grid item container spacing={3} key={keyGlobal}>
                    <Grid item xs={4}>
                      <TextField
                        name={keyMetadataKey}
                        label="Nombre / Dato"
                        value={extra.key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={hasError(errors, touched, keyMetadataKey)}
                        helperText={getHelperTextField(
                          errors,
                          touched,
                          keyMetadataKey,
                        )}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        name={ketMetadataValue}
                        label="Valor"
                        value={extra.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={hasError(errors, touched, ketMetadataValue)}
                        helperText={getHelperTextField(
                          errors,
                          touched,
                          ketMetadataValue,
                        )}
                      />
                    </Grid>

                    <Grid item xs="auto">
                      <IconButton
                        size="small"
                        onClick={() => remove(index)}
                        color="secondary"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </FieldArray>

        <Grid item container spacing={3} justify="center">
          <Grid item xs="auto">
            <ButtonWithCirculaProgress
              type="submit"
              loading={isSubmitting}
              disabled={!dirty}
              label="Guardar"
            />
          </Grid>
        </Grid>
      </Grid>
    )}
  </Formik>
);

BankForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
    account_type: PropTypes.string,
    currency_id: PropTypes.string,
    account_number: PropTypes.string,
    metadata: PropTypes.shape({
      extras: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

BankForm.defaultProps = {
  data: {},
  countries: [],
  currencies: [],
};

export default BankForm;
