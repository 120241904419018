// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';
import { forEach } from 'lodash';

import axiosInstance from './axiosInstance';

/**
 * @typedef StatusParam
 * @type {'request' | 'wait' | 'received' | 'complete' | 'cancel' | 'pending_by_validate'}
 */

/**
 * @typedef {Object} DataParamsUpdateOperationCashOut
 * @property {File} file - Archivo
 * @property {StatusParam} next_status - Estado
 */

/**
 * Actualizar los datos de una operacion Cash-Out
 * @param {String | Number} cashOutId - Id del Cash-Out a buscar
 * @param {DataParamsUpdateOperationCashOut} data - Datos a actualizar
 * @returns {Promise<AxiosResponse>}
 */
const updateOperationCashOut = (cashOutId, data = {}) => {
  const form = new FormData();
  forEach(data, (value, key) => {
    form.append(key, value);
  });

  return axiosInstance.patch(`/internal/admin/cash_outs/${cashOutId}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default updateOperationCashOut;
