import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { useFormik, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { toString, toNumber } from 'lodash';

import Input from '../Input';
import Select from '../Select';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { useRequest, useLazyRequest } from '../../hooks';
import { getConfigWebPage, getDashboardTransactions } from '../../api';
import { hasError, getHelperTextField } from '../../utils/errorInput';

import { initialValues, validationSchema } from './utils';

const DashboardSearchForm = ({ onReset, onProgresss, onCompleted }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currencyNetworksList, loadingCurrencyNetworksList] = useRequest(
    {
      initialState: [],
      request: getConfigWebPage,
      payload: {
        webPage: 'admin',
        route: '/dashboard',
      },
      transformResponse: (response) => {
        const parseCurrencyNetworksList = response.data.filters[0].values.map(
          (currencyNetwork) => ({
            label: currencyNetwork.filter,
            value: toString(currencyNetwork.crypto_network_id),
          }),
        );
        return parseCurrencyNetworksList;
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'Lo sentimos lo datos no pudieron ser cargados, intententelo nuevamente mas tarde',
          { variant: 'error' },
        );
      },
    },
    [],
  );

  const [, loadingSearch, , executeSearch] = useLazyRequest({
    request: getDashboardTransactions,
    withPostSuccess: (response) => {
      onCompleted(response.data);
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'Ha ocurrido un error, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const executeSubmit = useCallback(
    (values) => {
      onReset();
      const parseValues = {
        address: values.address,
        currencyNetworkId: toNumber(values.currencyNetwork),
      };

      onProgresss(parseValues);
      executeSearch(parseValues);
    },
    [onReset, onProgresss, executeSearch],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit: executeSubmit,
  });

  const { errors, touched, submitForm, getFieldProps } = formikBag;

  return (
    <FormikProvider value={formikBag}>
      <Grid item container spacing={2} justify="center">
        <Grid item xs={12}>
          <Typography variant="h5">Busqueda</Typography>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={7}>
            <Input
              name="address"
              label="Dirección"
              error={hasError(errors, touched, 'address')}
              helperText={getHelperTextField(errors, touched, 'address')}
              fullWidth
              size="small"
              autoComplete="off"
              {...getFieldProps('address')}
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              name="currencyNetwork"
              label="Moneda y Red"
              error={hasError(errors, touched, 'currencyNetwork')}
              helperText={getHelperTextField(
                errors,
                touched,
                'currencyNetwork',
              )}
              fullWidth
              size="small"
              autoComplete="off"
              data={currencyNetworksList}
              loading={loadingCurrencyNetworksList}
              {...getFieldProps('currencyNetwork')}
            />
          </Grid>

          <Grid item xs={2}>
            <ButtonWithCirculaProgress
              color="default"
              handleButtonClick={submitForm}
              loading={loadingSearch}
              label="BUSCAR"
              gutters={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormikProvider>
  );
};

DashboardSearchForm.propTypes = {
  onReset: PropTypes.func.isRequired,
  onProgresss: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default DashboardSearchForm;
