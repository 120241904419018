// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetRetirementById
 * @property {Object} data - Objeto con los datos del retiro
 */

/**
 * Obtener datos del retiro segun su ID
 * @param {String | Number} retirementId - Id del retiro a buscar
 * @returns {Promise<AxiosResponse<ResponseGetRetirementById>>} Informacion del retiro
 */
const getRetirementById = (retirementId) =>
  axiosInstance.get(`/internal/admin/retirements/${retirementId}`);

export default getRetirementById;
