import axiosInstance from './axiosInstance';

const createDashboardReport = ({ address, currencyNetworkId, from, to }) =>
  axiosInstance.post('/internal/admin/blockchain/dashboard/built_report', {
    address,
    crypto_network_id: currencyNetworkId,
    from,
    to,
  });

export default createDashboardReport;
