import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { PhotoCamera as PhotoCameraIcon } from '@material-ui/icons';

import { Formik, Form } from 'formik';
import { object, string, mixed } from 'yup';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { getHelperTextField, hasError } from '../../utils';

const listOfResources = [
  { title: 'Selecciona...', value: '' },
  { title: 'KriptoStore', value: 'kriptostore' },
  { title: 'kriptopayment', value: 'kriptopayment' },
  { title: 'Swap', value: 'swap' },
  { title: 'Cash-in', value: 'cash-in' },
  { title: 'Cash-out', value: 'cash-out' },
];

const getInitialValues = (data) => ({
  id: data?.id ?? '',
  resourceId: data?.resource_id ?? '',
  resource: data?.resource_type ?? '',
  amount: data?.amount ?? '',
  notes: data?.notes ?? '',
  paymentData: data?.payment_data ?? '',
  thash: data?.tx_hash ?? '',
  file: data?.url_voucher_image ?? '',
  status: data?.status ?? '',
});

const validationSchema = object().shape({
  resourceId: string().required('Campo Requerido'),
  resource: string().required('Campo Requerido'),
  amount: string().required('Campo Requerido'),
  notes: string().required('Campo Requerido'),
  paymentData: string().required('Campo Requerido'),
  file: mixed(),
  thash: string(),
});

const DevolutionsForm = ({ data, onSubmit, isView, isEdit }) => (
  <Formik
    initialValues={getInitialValues(data)}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      isSubmitting,
      dirty,
    }) => (
      <Grid item container component={Form} spacing={3}>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              Datos de la devolucion
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="resourceId"
              label="ID del recurso"
              value={values.resourceId}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'resourceId')}
              helperText={getHelperTextField(errors, touched, 'resourceId')}
              disabled={isView || isEdit}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="resource"
              label="Recurso"
              value={values.resource}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'resource')}
              helperText={getHelperTextField(errors, touched, 'resource')}
              select
              disabled={isView || isEdit}
            >
              {listOfResources.map((element) =>
                !isView && !isEdit ? (
                  <MenuItem value={element.value}>{element.title}</MenuItem>
                ) : (
                  <MenuItem disabled value={data.resource_type}>
                    {data.resource_type}
                  </MenuItem>
                ),
              )}
            </TextField>
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="amount"
              label="Monto"
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'amount')}
              helperText={getHelperTextField(errors, touched, 'amount')}
              disabled={isView || isEdit}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="notes"
              label="Notas"
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'notes')}
              helperText={getHelperTextField(errors, touched, 'notes')}
              multiline
              maxRows={6}
              disabled={isView || isEdit}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="paymentData"
              label="Datos del pago"
              value={values.paymentData}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              fullWidth
              error={hasError(errors, touched, 'paymentData')}
              helperText={getHelperTextField(errors, touched, 'paymentData')}
              disabled={isView || isEdit}
            />
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={4}>
              <TextField
                name="thash"
                label="Tx-Hash"
                value={values.thash}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                size="small"
                fullWidth
                error={hasError(errors, touched, 'thash')}
                helperText={getHelperTextField(errors, touched, 'thash')}
                disabled={isView}
              />
            </Grid>
            <Grid item xs="auto">
              {!values.file || isEdit ? (
                <>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => setFieldValue('file', e.target.files[0])}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="icon-button-file">
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      disabled={isView}
                      endIcon={<PhotoCameraIcon />}
                    >
                      Upload
                    </Button>
                  </label>

                  {hasError(errors, touched, 'file') && (
                    <FormHelperText error>
                      {getHelperTextField(errors, touched, 'file')}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <Grid item xs="auto">
                  <Link href={values.file} target="_blank" rel="noreferrer">
                    Ver voucher
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={3} justify="center">
          <Grid item xs="auto">
            <ButtonWithCirculaProgress
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || (isView && !isEdit)}
              label="Guardar"
            />
          </Grid>
        </Grid>
      </Grid>
    )}
  </Formik>
);

DevolutionsForm.propTypes = {
  data: PropTypes.shape({
    resource_id: PropTypes.string,
    resource_type: PropTypes.string,
    amount: PropTypes.string,
    notes: PropTypes.string,
    payment_data: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  isView: PropTypes.bool,
  isEdit: PropTypes.bool,
};

DevolutionsForm.defaultProps = {
  data: {},
  isView: false,
  isEdit: false,
};

export default DevolutionsForm;
