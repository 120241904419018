// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetMerchants
 * @property {Array.<Object>} data - Lista de merchants
 * @property {Number} current_page - Pagina Actual
 * @property {Number} per_page - Cantidad items por pagina
 * @property {Number} total_pages - Total de paginas
 * @property {Number} total_elements - Total de Items
 */

/**
 * @typedef {Object} FiltersObjectGetMerchants
 * @property {Number} [perPage] - Cantidad items por pagina
 * @property {Number} [page] - Pagina Actual
 * @property {'created_at'} [column] - Orden por columna
 * @property {'ASC' | 'DESC'} [orderColumn] - Orden por sentido
 * @property {String} [beneficiarie] - Buscar usuarios coincidentes
 * @property {'financial' | 'commercial'} [level] - Nivel de KYC
 */

/**
 * Obtener las lista de merchants
 * @param {FiltersObjectGetMerchants} [filters] - Filtros
 * @returns {Promise<AxiosResponse<ResponseGetMerchants>>} Listado de merchants
 */
const getMerchants = (filters = {}) => {
  const { perPage, page, column, orderColumn, beneficiarie, level } = filters;

  return axiosInstance.get('/internal/admin/merchants', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: orderColumn,
      username: beneficiarie,
      level,
    },
  });
};

export default getMerchants;
