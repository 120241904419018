export default () => ({
  cardContainer: {
    height: '100%',
    marginTop: 30,
    position: 'relative',
  },
  unselected: {
    opacity: 0.5,
  },
  button: {
    marginRight: 10,
  },
  buttonsContainer: {
    display: 'flex',
    backgroundColor: 'lightgrey',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 10,
    marginBottom: 15,
    width: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  iconDelete: {
    background: '#E2411F',
    position: 'absolute',
    color: 'white',
    width: 30,
    height: 30,
    marginTop: 3,
    marginLeft: 3,

    '&:hover': {
      backgroundColor: '#E2411F',
      opacity: 0.5,
    },
  },
  cardContent: {
    padding: '5px',
  },
});
