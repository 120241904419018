import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, ListItem, ListItemText, Box } from '@material-ui/core';

import ConfigIndexersSwitch from './Switch';
import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigIndexersCurrencyItem = ({
  mainName,
  cryptoNetWorkName,
  name,
  indexers,
}) => {
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText primary={name} className={classes.listItemCurrencyText} />

      <Box className={classes.switchersContainer}>
        {indexers.map((currencyIndexer) => (
          <ConfigIndexersSwitch
            key={`indexer-switch-${currencyIndexer.id}`}
            mainName={mainName}
            cryptoNetWorkName={cryptoNetWorkName}
            currencyName={name}
            id={currencyIndexer.id}
            name={currencyIndexer.name}
            active={Boolean(currencyIndexer.active)}
            current={Boolean(currencyIndexer.default)}
          />
        ))}
      </Box>
    </ListItem>
  );
};

ConfigIndexersCurrencyItem.propTypes = {
  mainName: PropTypes.string.isRequired,
  cryptoNetWorkName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  indexers: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.number.isRequired,
      default: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ConfigIndexersCurrencyItem;
