export default (theme) => ({
  menuPaper: {
    border: '1px solid #d3d4d5',
  },
  showIcon: {
    color: theme.palette.success.main,
  },
  editIcon: {
    color: theme.palette.info.main,
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
});
