import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, List } from '@material-ui/core';

import ConfigIndexersMainItem from './MainItem';
import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigIndexers = ({ indexers }) => {
  const classes = useStyles();

  return (
    <List dense disablePadding className={classes.root}>
      {Object.values(indexers).map((indexer) => (
        <ConfigIndexersMainItem
          key={`indexer-${indexer.name}`}
          name={indexer.name}
          cryptoNetworks={indexer.crypto_networks}
        />
      ))}
    </List>
  );
};

ConfigIndexers.propTypes = {
  indexers: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      crypto_networks: PropTypes.objectOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          currency: PropTypes.objectOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              indexers: PropTypes.arrayOf(
                PropTypes.shape({
                  active: PropTypes.number.isRequired,
                  default: PropTypes.number.isRequired,
                  id: PropTypes.number.isRequired,
                  name: PropTypes.string.isRequired,
                }),
              ).isRequired,
            }).isRequired,
          ).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

export default ConfigIndexers;
