import { object, string } from 'yup';

export const listOfResources = [
  { label: 'KriptoStore', value: 'kriptostore' },
  { label: 'KriptoPayment', value: 'kriptopayment' },
  { label: 'Swap', value: 'swap' },
  { label: 'Cash-in', value: 'cash-in' },
  { label: 'Cash-out', value: 'cash-out' },
  { label: 'Usuario', value: 'user' },
];

export const initialValues = {
  resourceId: '',
  resource: '',
  amount: '',
  currency: '',
  promotion: '',
};

export const validationSchema = object().shape({
  resourceId: string().required('Campo Requerido'),
  resource: string().required('Campo Requerido'),
  amount: string().required('Campo Requerido'),
  currency: string().required('Campo Requerido'),
  promotion: string().required('Campo Requerido'),
});
