import axiosInstance from './axiosInstance';

const addServicesConfigRate = ({
  kind,
  keyDescription,
  currencyId,
  country,
  amount,
  kriptoServiceId,
}) =>
  axiosInstance.post('/internal/admin/config/rates', {
    kind,
    key_description: keyDescription,
    currency_id: currencyId,
    country,
    amount,
    kripto_service_id: kriptoServiceId,
  });

export default addServicesConfigRate;
