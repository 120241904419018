import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { getAuthToken } from '../utils';

const PrivateRoute = ({ component, ...rest }) => {
  const hasToken = useMemo(() => {
    const token = getAuthToken();
    return !isEmpty(token);
  }, []);

  const CurrentComponent = component;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        hasToken ? (
          <CurrentComponent />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
