export const drawerWidthOpen = 240;
export const drawerWidthClose = 73;

export default (theme) => ({
  drawer: {
    width: drawerWidthOpen,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer,
  },
  drawerOpen: {
    width: drawerWidthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidthClose,
    overflowX: 'hidden',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    position: 'fixed',
    top: 0,
    backgroundColor: theme.palette.common.white,
    borderBottom: `1.5px solid ${theme.palette.divider}`,
    overflowX: 'hidden',
    width: drawerWidthOpen - theme.spacing(2),
    zIndex: theme.zIndex.appBar,
    ...theme.mixins.toolbar,
  },

  listContainer: {
    marginTop: 64,
  },
});
