import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  gutters: {
    margin: theme.spacing(1),
  },
  disabledLoadingButton: {
    color: theme.palette.action.disabled,
    cursor: 'default',
    pointerEvents: 'none',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textSuccess: {
    color: theme.palette.success.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.success.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  outlinedSuccess: {
    color: theme.palette.success.main,
    border: `1px solid ${fade(theme.palette.success.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.success.main}`,
      backgroundColor: fade(
        theme.palette.success.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  containedSuccess: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  progressSuccess: {
    color: theme.palette.success.main,
  },
});
