import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import ConfigIndexersCurrencyItem from './CurrencyItem';
import styles from './styles';

const useStyles = makeStyles(styles);

const ConfigIndexersCryptoNetworkItem = ({ mainName, name, currencies }) => {
  const classes = useStyles();

  const [isCollapse, setIsCollapse] = useState(true);

  const toggleIsCollapse = useCallback(() => {
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  return (
    <>
      <ListItem button onClick={toggleIsCollapse}>
        {isCollapse ? <ExpandLess /> : <ExpandMore />}

        <ListItemText primary={name} />
      </ListItem>

      <Collapse in={isCollapse} timeout="auto" unmountOnExit>
        <List dense disablePadding className={classes.listIndent}>
          {Object.values(currencies).map((currency) => (
            <ConfigIndexersCurrencyItem
              key={`currency-${currency.name}`}
              mainName={mainName}
              cryptoNetWorkName={name}
              name={currency.name}
              indexers={currency.indexers}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

ConfigIndexersCryptoNetworkItem.propTypes = {
  mainName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currencies: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      indexers: PropTypes.arrayOf(
        PropTypes.shape({
          active: PropTypes.number.isRequired,
          default: PropTypes.number.isRequired,
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  ).isRequired,
};

export default ConfigIndexersCryptoNetworkItem;
