import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import Input from '../Input';

import { getHelperTextField, hasError } from '../../utils/errorInput';
import { typesInputs } from './utils';

const CustomPlaceHolder = ({ startDate, endDate, nameDateRange, size }) => {
  const { errors, touched, handleBlur } = useFormikContext();

  const getKeyField = useCallback(
    (inputName) => `${nameDateRange}.${inputName}`,
    [nameDateRange],
  );

  const startDateKey = useMemo(() => getKeyField(typesInputs.startDate), [
    getKeyField,
  ]);
  const endDateKey = useMemo(() => getKeyField(typesInputs.endDate), [
    getKeyField,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Input
          name={startDateKey}
          value={startDate ?? ''}
          onChange={() => {}}
          onBlur={handleBlur}
          label="Desde"
          error={hasError(errors, touched, startDateKey)}
          helperText={getHelperTextField(errors, touched, startDateKey)}
          autoComplete="off"
          size={size}
        />
      </Grid>

      <Grid item xs={6}>
        <Input
          name={endDateKey}
          value={endDate ?? ''}
          onChange={() => {}}
          onBlur={handleBlur}
          label="Hasta"
          error={hasError(errors, touched, endDateKey)}
          helperText={getHelperTextField(errors, touched, endDateKey)}
          autoComplete="off"
          size={size}
        />
      </Grid>
    </Grid>
  );
};

CustomPlaceHolder.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  nameDateRange: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

CustomPlaceHolder.defaultProps = {
  startDate: null,
  endDate: null,
  size: 'medium',
};

export default memo(CustomPlaceHolder);
