/* eslint-disable no-unused-vars */
/* eslint-disable import/named */
import { useState, useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import updateClientStateByResource, {
  ResourceUpdate,
} from '../api/updateStateResourceByClient';

/**
 * Hook para actualizar el estado de un recurso segun el cliente
 * @param {String | Number} clientId - Id del cliente a buscar
 * @param {ResourceUpdate} resource - Recurso a modificar
 * @param {String} currentState - Estado Actual
 * @param {Function} callback - Callback a ejecutar luego
 */
export const useUpdateStateClient = (id, resource, currentState, callback) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingFetch, setLoadingFetch] = useState(false);

  const updateStatus = useCallback(
    async (nextState) => {
      try {
        setLoadingFetch(true);

        await updateClientStateByResource(id, resource, nextState);

        enqueueSnackbar('Exitoso!!!', {
          variant: 'success',
          key: 'successUpdateSucess',
        });

        callback();
      } catch (err) {
        enqueueSnackbar('Ha ocurrido un error intentelo nuevamente', {
          variant: 'error',
          key: 'successUpdateError',
        });
      } finally {
        setLoadingFetch(false);
      }
    },
    [id, resource, enqueueSnackbar, callback],
  );

  const handleApprove = useCallback(() => updateStatus('approved'), [
    updateStatus,
  ]);

  const handleCancel = useCallback(() => updateStatus('cancelled'), [
    updateStatus,
  ]);

  const showButtons = useMemo(() => currentState === 'checking', [
    currentState,
  ]);

  return [loadingFetch, handleApprove, handleCancel, showButtons];
};
