import axiosInstance from './axiosInstance';

const getCurrencyNetworks = () =>
  axiosInstance.get('/internal/config/web_pages', {
    params: {
      web_page: 'admin',
      route: '/payment_wallets',
    },
  });

export default getCurrencyNetworks;
