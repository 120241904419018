// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef ResourceUpdate
 * @type {'fiscal_informations' | 'personal_informations' | 'domiciles'}
 */

/**
 * @typedef NextStateResouceUpdate
 * @type {'approved' | 'cancelled'}
 */

/**
 * Actualizar el estado de un recurso segun el cliente
 * @param {String | Number} clientId - Id del cliente a buscar
 * @param {ResourceUpdate} resource - Recurso a modificar
 * @param {NextStateResouceUpdate} nextState - Nuevo estado
 * @returns {Promise<AxiosResponse>}
 */
const updateClientStateByResource = (clientId, resource, nextState) =>
  axiosInstance.patch(`/internal/admin/clients/${clientId}/${resource}`, {
    next_state: nextState,
  });

export default updateClientStateByResource;
