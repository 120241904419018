import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

const NestingRoute = ({ path: originalPath, nesting, isPrivate, ...rest }) => {
  const ComponentRoute = useMemo(() => (isPrivate ? PrivateRoute : Route), [
    isPrivate,
  ]);

  return (
    <Route path={originalPath}>
      <Switch>
        <ComponentRoute exact path={originalPath} {...rest} />
        {nesting.map(({ path: subRoutePath, ...restNested }) => (
          <ComponentRoute
            key={subRoutePath}
            path={`${originalPath}${subRoutePath}`}
            {...restNested}
          />
        ))}
      </Switch>
    </Route>
  );
};

NestingRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  isPrivate: PropTypes.bool,
  nesting: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
      exact: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
};

NestingRoute.defaultProps = {
  isPrivate: false,
};

export default NestingRoute;
