import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import TableListVertical from '../TableListVertical';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { useLazyRequest } from '../../hooks';
import { getLinkDownloadReport } from '../../api';
import { formatDate } from '../../utils/helpers';

import styles from './styles';

const useStyles = makeStyles(styles);

const ReportsDownloads = ({ data, loading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [loadingDowload, setLoadingDowload] = useState(null);
  const [, , , executeDowloadReport] = useLazyRequest({
    request: getLinkDownloadReport,
    withPostSuccess: (response) => {
      window.open(response.data.url, '_blank');
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo descargar el archivo, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });
  const handleDownloadReport = useCallback(
    async (id) => {
      setLoadingDowload(id);
      await executeDowloadReport(id);
      setLoadingDowload(null);
    },
    [executeDowloadReport],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Tipo de reporte',
        selector: 'type',
        sortable: false,
        right: true,
      },
      {
        name: 'Fecha de creación',
        selector: 'created_at',
        format: (row) => formatDate(row.created_at, 'DD/MM/YYYY hh:mm A'),
        sortable: false,
        right: true,
      },
      {
        name: 'Última descarga',
        selector: 'last_download',
        format: (row) =>
          row.last_download
            ? formatDate(row.last_download, 'DD/MM/YYYY hh:mm A')
            : null,
        sortable: false,
        right: true,
      },
      {
        name: 'Opciones',
        selector: 'id',
        format: (row) => (
          <ButtonWithCirculaProgress
            variant="text"
            color="primary"
            label="Descargar"
            handleButtonClick={() => handleDownloadReport(row.id)}
            startIcon={<DownloadIcon />}
            loading={isEqual(loadingDowload, row.id)}
            gutters={false}
          />
        ),
        sortable: false,
        right: true,
        hide: (row) => !row.downloadable,
      },
    ],
    [handleDownloadReport, loadingDowload],
  );

  const redirectToHistory = useCallback(
    () => history.push('/reports/history'),
    [history],
  );

  return (
    <TableListVertical
      data={data}
      columns={columns}
      pageNumber={1}
      loading={loading}
      totalRows={4}
      handlePageChange={redirectToHistory}
      buttonPaginatorText="VER HISTORIAL COMPLETO"
      title="DESCARGAS"
      className={classes.cardContent}
    />
  );
};

ReportsDownloads.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ReportsDownloads;
