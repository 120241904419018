import { string, object } from 'yup';

export const initialValues = {
  address: '',
  currencyNetwork: '',
};

export const validationSchema = object().shape({
  address: string().required('Campo Requerido'),
  currencyNetwork: string().required('Campo Requerido'),
});
