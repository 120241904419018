import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { isUndefined } from 'lodash';
import { useSnackbar } from 'notistack';

import CardWithProgressLoading from '../CardWithProgressLoading';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

import { updateConfigUser } from '../../api';

const getValues = ({ active, toDonations }) => ({
  active,
  ...(!isUndefined(toDonations) ? { toDonations } : {}),
});

const ConfigEditUser = ({ id, active, toDonations, refetchData }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await updateConfigUser({ id, data: values });

        enqueueSnackbar('Usuario actualizado correctamente', {
          variant: 'success',
          key: 'successEditUserConfig',
        });

        refetchData?.();
      } catch (err) {
        enqueueSnackbar('Hubo error, intentelo nuevamente', {
          variant: 'error',
          key: 'errorEditUserConfig',
        });
      }
    },
    [id, enqueueSnackbar, refetchData],
  );

  return (
    <CardWithProgressLoading loading={false}>
      <Formik
        initialValues={getValues({ active, toDonations })}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, isSubmitting, dirty }) => (
          <Grid item container component={Form} spacing={3} justify="center">
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="active"
                      checked={values.active}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Activo?"
                />
              </Grid>

              {!isUndefined(toDonations) && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="toDonations"
                        checked={values.toDonations}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Donaciones?"
                  />
                </Grid>
              )}

              <Grid item container justify="center">
                <Grid item xs="auto">
                  <ButtonWithCirculaProgress
                    type="submit"
                    loading={isSubmitting}
                    disabled={!dirty}
                    label="Guardar"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </CardWithProgressLoading>
  );
};

ConfigEditUser.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
  toDonations: PropTypes.bool,
  refetchData: PropTypes.func,
};

ConfigEditUser.defaultProps = {
  active: undefined,
  toDonations: undefined,
  refetchData: () => {},
};

export default ConfigEditUser;
