// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetListCountries
 * @property {Array.<Object>} data - Listado de paises
 */

/**
 * Obtener la lista de paises
 * @returns {Promise<AxiosResponse<ResponseGetListCountries>>} Listado de paises
 */
const getCountries = () => axiosInstance.get('/public/countries');

export default getCountries;
