// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseGetTasks
 * @property {Array.<Object>} data - Lista de tareas
 * @property {Number} current_page - Pagina Actual
 * @property {Number} per_page - Cantidad items por pagina
 * @property {Number} total_pages - Total de paginas
 * @property {Number} total_elements - Total de Items
 */

/**
 * @typedef {Object} FiltersObjectGetTasks
 * @property {Number} [perPage] - Cantidad items por pagina
 * @property {Number} [page] - Pagina Actual
 */

/**
 * Obtener las lista de tareas
 * @param {FiltersObjectGetOperations} [filters] - Filtros
 * @returns {Promise<AxiosResponse<ResponseGetTasks>>} Listado de tareas
 */
const getTasks = (filters = {}) => {
  const { perPage, page } = filters;

  return axiosInstance.get('/internal/admin/tasks', {
    params: {
      per_page: perPage,
      page,
    },
  });
};

export default getTasks;
