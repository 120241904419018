import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import Input from '../Input';
import Select from '../Select';
import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';
import { hasError, getHelperTextField } from '../../utils';
import { useLazyRequest } from '../../hooks';
import { getLimits, updateLimits } from '../../api';

import {
  SERVICES_LIST,
  CURRENCIES_LIST,
  FIELDS_NAME,
  initialValues,
  validationSchema,
  formatInputAmount,
} from './utils';

const LimitsForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isCompletedModify, setIsCompletedModify] = useState(false);

  const [, loadingModify, , executeModify] = useLazyRequest({
    request: updateLimits,
    withPostSuccess: () => {
      enqueueSnackbar('Se actualizo los limites correctamente', {
        variant: 'success',
      });

      setIsCompletedModify(true);
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo actualizar los limites, intententelo nuevamente mas tarde',
        { variant: 'error' },
      );
    },
  });

  const onModify = useCallback(
    (values) => {
      setIsCompletedModify(false);

      executeModify({
        userId: values[FIELDS_NAME.USER_ID],
        kriptoService: values[FIELDS_NAME.SERVICE_ID],
        currency: values[FIELDS_NAME.CURRENCY_ID],
        minimunGeneral: values[FIELDS_NAME.MINIMUN_AMOUNT],
        maximumMonth: values[FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT],
        maximumYear: values[FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT],
      });
    },
    [executeModify],
  );

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setValues,
    submitForm,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onModify,
  });

  const [step, setStep] = useState('search');
  const [defaultAmounts, setDefaultAmounts] = useState(null);

  const isStepModifiy = useMemo(() => step === 'modify', [step]);

  const [, loadingSearch, , executeSearch] = useLazyRequest({
    request: getLimits,
    withPostSuccess: (response) => {
      const amounts = {
        [FIELDS_NAME.MINIMUN_AMOUNT]: response.data.minimun_general,
        [FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT]: response.data.maximum_month,
        [FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT]: response.data.maximum_year,
      };

      setValues({
        ...values,
        ...amounts,
      });

      setDefaultAmounts(amounts);

      setStep('modify');
    },
    withPostFailure: () => {
      enqueueSnackbar('Hubo error, intentelo nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSearch = useCallback(() => {
    executeSearch({
      userId: values[FIELDS_NAME.USER_ID],
      kriptoService: values[FIELDS_NAME.SERVICE_ID],
      currency: values[FIELDS_NAME.CURRENCY_ID],
    });
  }, [executeSearch, values]);

  const onResetSearch = useCallback(() => {
    resetForm();
    setDefaultAmounts(null);
    setStep('search');
  }, [resetForm]);

  const isEnabledSearch = useMemo(
    () =>
      Boolean(values[FIELDS_NAME.USER_ID]) &&
      Boolean(values[FIELDS_NAME.SERVICE_ID]) &&
      Boolean(values[FIELDS_NAME.CURRENCY_ID]),
    [values],
  );

  const isEnabledModify = useMemo(() => {
    const formAmounts = {
      [FIELDS_NAME.MINIMUN_AMOUNT]: values[FIELDS_NAME.MINIMUN_AMOUNT],
      [FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT]:
        values[FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT],
      [FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT]:
        values[FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT],
    };

    return JSON.stringify(formAmounts) !== JSON.stringify(defaultAmounts);
  }, [defaultAmounts, values]);

  useEffect(() => {
    if (isCompletedModify) {
      onResetSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompletedModify]);

  return (
    <Grid item container justify="center">
      <Grid item xs={12} lg={4} container spacing={4}>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Input
              label="ID de usuario"
              error={hasError(errors, touched, FIELDS_NAME.USER_ID)}
              helperText={getHelperTextField(
                errors,
                touched,
                FIELDS_NAME.USER_ID,
              )}
              fullWidth
              size="small"
              autoComplete="off"
              {...getFieldProps(FIELDS_NAME.USER_ID)}
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              label="Kriptoservicio"
              error={hasError(errors, touched, FIELDS_NAME.SERVICE_ID)}
              helperText={getHelperTextField(
                errors,
                touched,
                FIELDS_NAME.SERVICE_ID,
              )}
              fullWidth
              size="small"
              autoComplete="off"
              data={SERVICES_LIST}
              {...getFieldProps(FIELDS_NAME.SERVICE_ID)}
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              label="Moneda"
              error={hasError(errors, touched, FIELDS_NAME.CURRENCY_ID)}
              helperText={getHelperTextField(
                errors,
                touched,
                FIELDS_NAME.CURRENCY_ID,
              )}
              fullWidth
              size="small"
              autoComplete="off"
              data={CURRENCIES_LIST}
              {...getFieldProps(FIELDS_NAME.CURRENCY_ID)}
            />
          </Grid>

          <Grid item container justify="center" spacing={2}>
            <Grid item>
              <ButtonWithCirculaProgress
                color="primary"
                variant="contained"
                handleButtonClick={onSearch}
                loading={loadingSearch}
                label="BUSCAR"
                gutters={false}
                disabled={!isEnabledSearch}
              />
            </Grid>

            <Grid item>
              <ButtonWithCirculaProgress
                color="primary"
                variant="outlined"
                handleButtonClick={onResetSearch}
                loading={false}
                label="REINICIAR"
                gutters={false}
                disabled={!isEnabledSearch}
              />
            </Grid>
          </Grid>
        </Grid>

        {isStepModifiy && defaultAmounts && (
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Input
                label="Monto minimo"
                error={hasError(errors, touched, FIELDS_NAME.MINIMUN_AMOUNT)}
                helperText={getHelperTextField(
                  errors,
                  touched,
                  FIELDS_NAME.MINIMUN_AMOUNT,
                )}
                fullWidth
                size="small"
                type="number"
                autoComplete="off"
                {...getFieldProps(FIELDS_NAME.MINIMUN_AMOUNT)}
                onKeyDown={formatInputAmount}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                label="Monto maximo mensual"
                error={hasError(
                  errors,
                  touched,
                  FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT,
                )}
                helperText={getHelperTextField(
                  errors,
                  touched,
                  FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT,
                )}
                fullWidth
                size="small"
                type="number"
                autoComplete="off"
                {...getFieldProps(FIELDS_NAME.MAXIMUN_MONTHLY_AMOUNT)}
                onKeyDown={formatInputAmount}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                label="Monto maximo anual"
                error={hasError(
                  errors,
                  touched,
                  FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT,
                )}
                helperText={getHelperTextField(
                  errors,
                  touched,
                  FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT,
                )}
                fullWidth
                size="small"
                type="number"
                autoComplete="off"
                {...getFieldProps(FIELDS_NAME.MAXIMUN_ANNUAL_AMOUNT)}
                onKeyDown={formatInputAmount}
              />
            </Grid>

            <Grid item container justify="center">
              <ButtonWithCirculaProgress
                color="primary"
                variant="contained"
                handleButtonClick={submitForm}
                loading={loadingModify}
                label="Modificar"
                gutters={false}
                disabled={!isEnabledModify}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LimitsForm;
