import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import ButtonWithCirculaProgress from '../ButtonWithCirculaProgress';

const ConfirmationOperationModal = ({
  id,
  open = false,
  onAccept,
  loadingAccept,
  onCancel,
  ...others
}) => {
  const titleId = useMemo(() => `confirmation-modal-title-${id}`, [id]);
  const contentId = useMemo(() => `confirmation-modal-description-${id}`, [id]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby={titleId}
      aria-describedby={contentId}
      {...others}
    >
      <DialogTitle id={titleId}>¿Estas seguro?</DialogTitle>

      <DialogContent>
        <DialogContentText id={contentId}>
          Seguro desea realizar esta operación
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="default" variant="outlined">
          Volver
        </Button>

        <ButtonWithCirculaProgress
          variant="outlined"
          color="primary"
          label="Continuar"
          gutters={false}
          handleButtonClick={onAccept}
          loading={loadingAccept}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmationOperationModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  loadingAccept: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationOperationModal;
