import axiosInstance from './axiosInstance';

const saveShippingData = ({ shippingData }) => {
  return axiosInstance.patch(
    `/internal/admin/kriptostores/8/upload_shipping_data`,
    {
      shipping_data: shippingData,
    },
  );
};

export default saveShippingData;
