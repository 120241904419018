import axiosInstance from './axiosInstance';

const getKriptoStore = (filters = {}) => {
  const { perPage, page, merchantId, clientId, status, sort } = filters;
  const column = sort ? 'created_at' : null;
  return axiosInstance.get('/internal/admin/kriptostores', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: sort,
      merchant_id: merchantId,
      client_id: clientId,
      state: status,
    },
  });
};

export default getKriptoStore;
