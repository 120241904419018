import { isEqual } from 'lodash';

export const getStatus = (status) => {
  let description = '';

  if (isEqual(status, 'in_process')) description = 'En proceso';
  if (isEqual(status, 'paid')) description = 'Pagado';
  if (isEqual(status, 'confirmed')) description = 'Completo';
  if (isEqual(status, 'rejected')) description = 'Rechazado';
  if (isEqual(status, 'expired')) description = 'Expirado';
  if (isEqual(status, 'cancel')) description = 'Cancelado';
  if (isEqual(status, 'paid_partial')) description = 'Pago Parcial';
  if (isEqual(status, 'pending')) description = 'Pendiente';
  if (isEqual(status, 'checking')) description = 'Verificando';
  if (isEqual(status, 'approving')) description = 'Aprobado';
  if (isEqual(status, 'canceling')) description = 'Cancelado';
  if (isEqual(status, 'paid_partial_and_expired'))
    description = 'Expirado (Pagado Parcialmente)';

  return description;
};
