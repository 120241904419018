import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ServiceConfigForm from '../ServiceConfigForm';
import styles from './styles';

const useStyles = makeStyles(styles);

const DialogEditServiceConfig = ({
  open = false,
  onClose,
  kriptoServiceId,
  data,
  countries,
  currencies,
  onSuccess,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle>
        Editar {kriptoServiceId}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.headerCloseButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <ServiceConfigForm
          data={data}
          isEdit
          serviceId={kriptoServiceId}
          countries={countries}
          currencies={currencies}
          onSuccess={onSuccess}
        />
      </DialogContent>
    </Dialog>
  );
};

DialogEditServiceConfig.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  kriptoServiceId: PropTypes.number,
  data: PropTypes.shape({
    active: PropTypes.bool,
    amount: PropTypes.string,
    country: PropTypes.string,
    created_at: PropTypes.string,
    created_by: PropTypes.number,
    currency_id: PropTypes.string,
    deactivated_by: PropTypes.string,
    id: PropTypes.number,
    key_description: PropTypes.string,
    kind: PropTypes.string,
    kripto_service_id: PropTypes.number,
    updated_at: PropTypes.string,
  }),
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onSuccess: PropTypes.func.isRequired,
};

DialogEditServiceConfig.defaultProps = {
  data: {},
  countries: [],
  currencies: [],
  kriptoServiceId: null,
};

export default DialogEditServiceConfig;
