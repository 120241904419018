import axiosInstance from './axiosInstance';

const getWalletsPayments = ({ pageNumber, pageSize, currencyNetwork }) =>
  axiosInstance.get('/internal/admin/config/addresses', {
    params: {
      per_page: pageSize,
      page: pageNumber,
      currency_and_network: currencyNetwork,
    },
  });

export default getWalletsPayments;
