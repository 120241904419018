// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ParamsUpdateKriptoPayment
 * @property {String | Number} id - Id de la kriptopayment a modificar
 * @property {'complete ' | 'cancel'} nextState - Nuevo estado
 */

/**
 * Modifica el estado de una kriptopayment
 * @param {ParamsUpdateKriptoPayment} params
 * @returns {Promise<AxiosResponse>}
 */
const updateStateKriptoPayment = (params) => {
  const { id, nextState } = params;

  return axiosInstance.patch(`/internal/admin/kriptopayments/${id}`, {
    next_state: nextState,
  });
};

export default updateStateKriptoPayment;
