import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Menu as MenuIcon, ExitToApp as LogoutIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { removeAuthToken } from '../../utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const Header = ({ title, openMenu, handleOpenMenu }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleLogout = useCallback(() => {
    removeAuthToken();
    history.push('/login');
  }, [history]);

  return (
    <AppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: openMenu,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open lateral menu"
          onClick={handleOpenMenu}
          edge="start"
          className={classNames(classes.menuButton, {
            [classes.hide]: openMenu,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.title}>
          {title}
        </Typography>
        <Tooltip title="Logout">
          <IconButton
            color="inherit"
            aria-label="logout"
            onClick={handleLogout}
            edge="end"
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  openMenu: PropTypes.bool.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
};

export default Header;
