// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef {Object} ResponseLogin
 * @property {String} token - Token de seguridad
 */

/**
 * Ejecuta el inicio de sesion
 * @param {String} username - Usuario o email
 * @param {String} password - Contraseña
 * @returns {Promise<AxiosResponse<ResponseLogin>>} Informacion para el inicio de la sesion
 */
const login = (username, password) =>
  axiosInstance.post('/public/user/login', {
    username,
    password,
    from: 'admin',
  });

export default login;
