import axiosInstance from './axiosInstance';

const forceCompletedPayment = ({ id, txHash }) => {
  const body = txHash ? { tx_hash: txHash } : undefined;

  return axiosInstance.post(
    `/internal/admin/payments/force_complete/${id}`,
    body,
  );
};

export default forceCompletedPayment;
