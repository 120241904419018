import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '@material-ui/core';

import Header from '../Header';
import LateralMenu from '../LateralMenu';

import styles from './styles';

const useStyles = makeStyles(styles);

const Layout = ({ titleHeader, children }) => {
  const classes = useStyles();
  const [openLateralMenu, setOpenLateralMenu] = useState(false);

  const handleOpenLateralMenu = useCallback(() => {
    setOpenLateralMenu(!openLateralMenu);
  }, [openLateralMenu]);

  return (
    <Box className={classes.wrapped}>
      <Header
        title={titleHeader}
        openMenu={openLateralMenu}
        handleOpenMenu={handleOpenLateralMenu}
      />
      <LateralMenu
        openMenu={openLateralMenu}
        handleCloseMenu={handleOpenLateralMenu}
      />
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

Layout.propTypes = {
  titleHeader: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
