// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

const getClients = (filters = {}) => {
  const { perPage, page, column, orderColumn, username, level } = filters;

  return axiosInstance.get('/internal/admin/clients', {
    params: {
      per_page: perPage,
      page,
      column,
      order_column: orderColumn,
      username,
      level,
    },
  });
};

export default getClients;
