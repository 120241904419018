import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, Grid, Button, Box } from '@material-ui/core';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ButtonWithCirculaProgress } from '..';
import CardComponent from './Card';

import styles from './styles';

const useStyles = makeStyles(styles);

const TableListSliders = ({ list, handleDelete, handleOrder, loading }) => {
  const [selected, setSelected] = useState('desktop1280');
  const [cards, setCards] = useState([]);
  const classes = useStyles();

  const reorder = (arrayData) => {
    const { destination, source } = arrayData;
    const result = { ...cards };
    const [removed] = result[selected].splice(source.index, 1);
    result[selected].splice(destination.index, 0, removed);
    setCards(result);
  };

  useEffect(() => {
    setCards(list);
  }, [list]);

  const renderCard = (card, index) => (
    <Draggable key={card.id} draggableId={card.id.toString()} index={index}>
      {(provided) => (
        <Grid
          xs={12}
          md={4}
          lg={4}
          className={classes.cardContent}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <CardComponent
            key={card.id}
            index={index}
            id={card.id}
            url={card[selected]}
            handleDelete={handleDelete}
          />
        </Grid>
      )}
    </Draggable>
  );

  return (
    <Card className={classNames(classes.cardContainer)}>
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.buttonsContainer}>
            <Button
              className={classNames(classes.button, {
                [classes.unselected]: selected !== 'desktop1280',
              })}
              variant="contained"
              color="primary"
              onClick={() => setSelected('desktop1280')}
            >
              Desktop 1280
            </Button>
            <Button
              className={classNames(classes.button, {
                [classes.unselected]: selected !== 'tablet1024',
              })}
              variant="contained"
              color="primary"
              onClick={() => setSelected('tablet1024')}
            >
              Tablet 1024
            </Button>
            <Button
              className={classNames(classes.button, {
                [classes.unselected]: selected !== 'tablet768',
              })}
              variant="contained"
              color="primary"
              onClick={() => setSelected('tablet768')}
            >
              Tablet 768
            </Button>
            <Button
              className={classNames(classes.button, {
                [classes.unselected]: selected !== 'mobile480',
              })}
              variant="contained"
              color="primary"
              onClick={() => setSelected('mobile480')}
            >
              Mobile 480
            </Button>
            <Button
              className={classNames(classes.button, {
                [classes.unselected]: selected !== 'mobile320',
              })}
              variant="contained"
              color="primary"
              onClick={() => setSelected('mobile320')}
            >
              Mobile 320
            </Button>
          </Box>
        </Grid>
        {/* Image slider */}
        <DragDropContext onDragEnd={reorder}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {Boolean(cards.length) &&
                  cards?.map(
                    (card, i) => card[selected] && renderCard(card, i),
                  )}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>

        <Grid item container justify="center">
          <ButtonWithCirculaProgress
            type="button"
            loading={loading}
            handleButtonClick={() => handleOrder(cards)}
            label="Guardar"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

TableListSliders.propTypes = {
  list: PropTypes.arrayOf().isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TableListSliders;
