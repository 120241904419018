import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import { isEmpty, startCase, toString } from 'lodash';

import LinkRouter from '../LinkRouter';
import TableList from '../TableList';
import MenuActionsTable from '../MenuActionsTable';
import FiltersTableWrapped from '../FiltersTableWrapped';

import { getOperations } from '../../api';
import {
  useFetchBeneficiaries,
  useDataTableOptions,
  useFiltersValue,
} from '../../hooks';
import { getClientName, getStatusDescription } from '../../utils';

const OperationsTable = ({ typeOperation }) => {
  const listBeneficiaries = useFetchBeneficiaries('clients');
  const listMerchants = useFetchBeneficiaries('merchants');

  const {
    options: { loading: loadingFetch, data, totalRows, perPage },
    setOptionByKey,
  } = useDataTableOptions({
    loading: true,
    data: [],
    totalRows: 0,
    perPage: 25,
  });

  const {
    valuesFilters: { beneficiarie, merchant, status },
    setValueFilterByKey,
    handleChangeInputsFilter,
  } = useFiltersValue({
    beneficiarie: '',
    merchant: '',
    status: '',
  });

  const columns = useMemo(
    () => [
      {
        name: 'Acciones',
        cell: (row) => (
          <MenuActionsTable
            idItem={row.id}
            resourcePath={typeOperation}
            options={{ view: true, edit: true, delet: false }}
          />
        ),
        allowOverflow: true,
        button: true,
        width: '100px',
      },
      {
        name: 'ID',
        selector: 'id',
        width: '60px',
        sortable: false,
      },
      {
        name: 'Creador',
        selector: 'merchant.name',
        sortable: false,
      },
      {
        name: 'Creado el',
        selector: 'created_at',
        format: (row) => new Date(row.created_at).toLocaleDateString(),
        sortable: false,
      },
      {
        name: 'Beneficiario',
        selector: 'client',
        cell: (row) =>
          row.client ? (
            <LinkRouter
              to={`/clients/${row.client.client_id}`}
              color="textSecondary"
            >
              {getClientName(row.client)}
            </LinkRouter>
          ) : null,
        sortable: false,
      },
      {
        name: 'Estado',
        selector: 'status',
        format: (row) => getStatusDescription(row.status, typeOperation),
        sortable: false,
      },
      {
        name: 'Entrada',
        selector: 'amount_in',
        format: (row) => `${row.currency_in} ${row.amount_in}`,
        sortable: false,
      },
      {
        name: 'Salida',
        selector: 'amount_out',
        format: (row) => `${row.currency_out} ${row.amount_out}`,
        sortable: false,
      },
    ],
    [typeOperation],
  );

  const fetchOperations = useCallback(
    async ({
      perPage: perPageParam,
      page,
      column: columnParam,
      orderColumn: orderColumnParam,
      merchantId,
      clientId,
      status: statusParam,
    }) => {
      setOptionByKey('loading', true);

      try {
        const { data: dataResponse } = await getOperations({
          perPage: perPageParam,
          page,
          column: columnParam,
          orderColumn: orderColumnParam,
          merchantId,
          clientId,
          type: typeOperation,
          status: statusParam,
        });

        setOptionByKey('data', dataResponse.data);
        setOptionByKey('totalRows', dataResponse.total_elements);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setOptionByKey('loading', false);
      }
    },
    [typeOperation, setOptionByKey],
  );

  useEffect(() => {
    fetchOperations({ page: 1, perPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOperations]);

  const parseValuesFilters = useMemo(
    () => ({
      clientId: !isEmpty(beneficiarie) ? beneficiarie : null,
      merchantId: !isEmpty(merchant) ? merchant : null,
      status: !isEmpty(status) ? status : null,
    }),
    [beneficiarie, merchant, status],
  );

  const handlePageChange = useCallback(
    async (newPage) => {
      await fetchOperations({
        perPage,
        page: newPage,
        ...parseValuesFilters,
      });
    },
    [fetchOperations, perPage, parseValuesFilters],
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, newPage) => {
      await fetchOperations({
        perPage: newPerPage,
        page: newPage,
        ...parseValuesFilters,
      });
      setOptionByKey('perPage', newPerPage);
    },
    [fetchOperations, setOptionByKey, parseValuesFilters],
  );

  const handleFilter = useCallback(async () => {
    await fetchOperations({
      page: 1,
      perPage,
      ...parseValuesFilters,
    });
  }, [fetchOperations, perPage, parseValuesFilters]);

  const handleClearFilter = useCallback(async () => {
    if (!isEmpty(beneficiarie) || !isEmpty(merchant) || !isEmpty(status)) {
      setValueFilterByKey('beneficiarie', '');
      setValueFilterByKey('merchant', '');
      setValueFilterByKey('status', '');
      await fetchOperations({
        page: 1,
        perPage,
      });
    }
  }, [
    beneficiarie,
    merchant,
    status,
    setValueFilterByKey,
    fetchOperations,
    perPage,
  ]);

  const listStatus = useMemo(() => {
    return [
      {
        value: 'request',
        label: getStatusDescription('request', typeOperation),
      },
      { value: 'wait', label: getStatusDescription('wait', typeOperation) },
      {
        value: 'received',
        label: getStatusDescription('received', typeOperation),
      },
      {
        value: 'complete',
        label: getStatusDescription('complete', typeOperation),
      },
      { value: 'cancel', label: getStatusDescription('cancel', typeOperation) },
      {
        value: 'pending_by_validate',
        label: getStatusDescription('pending_by_validate', typeOperation),
      },
    ];
  }, [typeOperation]);

  const filtersComponent = useMemo(() => {
    return (
      <FiltersTableWrapped
        handleFilter={handleFilter}
        handleClear={handleClearFilter}
      >
        <TextField
          name="beneficiarie"
          label="Beneficiario"
          value={beneficiarie}
          onChange={handleChangeInputsFilter}
          variant="outlined"
          size="small"
          select
        >
          <MenuItem value="">Selecciona...</MenuItem>
          {listBeneficiaries.map((benef) => (
            <MenuItem key={benef.id} value={toString(benef.id)}>
              {benef.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          name="merchant"
          label="Merchant"
          value={merchant}
          onChange={handleChangeInputsFilter}
          variant="outlined"
          size="small"
          select
        >
          <MenuItem value="">Selecciona...</MenuItem>
          {listMerchants.map((merch) => (
            <MenuItem key={merch.id} value={toString(merch.id)}>
              {merch.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          name="status"
          label="Estado"
          value={status}
          onChange={handleChangeInputsFilter}
          variant="outlined"
          size="small"
          select
        >
          <MenuItem value="">Selecciona...</MenuItem>
          {listStatus.map((stat) => (
            <MenuItem key={stat.value} value={stat.value}>
              {stat.label}
            </MenuItem>
          ))}
        </TextField>
      </FiltersTableWrapped>
    );
  }, [
    handleFilter,
    handleClearFilter,
    beneficiarie,
    handleChangeInputsFilter,
    listBeneficiaries,
    merchant,
    listMerchants,
    status,
    listStatus,
  ]);

  const titleTable = useMemo(() => {
    const pluralizeTypeOperation = `${startCase(typeOperation)}'s`;

    return `Listado de ${pluralizeTypeOperation}`;
  }, [typeOperation]);

  return (
    <TableList
      data={data}
      columns={columns}
      title={titleTable}
      filtersComponent={filtersComponent}
      perPage={perPage}
      loading={loadingFetch}
      totalRows={totalRows}
      handlePerRowsChange={handlePerRowsChange}
      handlePageChange={handlePageChange}
    />
  );
};

OperationsTable.propTypes = {
  typeOperation: PropTypes.oneOf(['cash-in', 'cash-out', 'payment', 'swap'])
    .isRequired,
};

export default OperationsTable;
