// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * @typedef StatusParam
 * @type {'request' | 'wait' | 'received' | 'complete' | 'cancel' | 'pending_by_validate'}
 */

/**
 * @typedef {Object} DataParamsUpdateOperationCashIn
 * @property {String} tx_hash - Token de la transaccion
 * @property {StatusParam} next_status - Estado
 */

/**
 * Actualizar los datos de una operacion Cash-In
 * @param {String | Number} cashInId - Id del Cash-In a buscar
 * @param {DataParamsUpdateOperationCashIn} data - Datos a actualizar
 * @returns {Promise<AxiosResponse>}
 */
const updateOperationCashIn = (cashInId, data) =>
  axiosInstance.patch(`/internal/admin/cash_ins/${cashInId}`, data);

export default updateOperationCashIn;
