// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios';

import axiosInstance from './axiosInstance';

/**
 * Modifica el estado de una operacion
 * @param {String | Number} operationId - Id de la operacion a modificar
 * @param {'completed ' | 'cancelled'} newState - Nuevo estado
 * @returns {Promise<AxiosResponse>}
 */
const updateStateOperation = (operationId, newState) =>
  axiosInstance.patch(`/internal/admin/operations/${operationId}`, {
    new_state: newState,
  });

export default updateStateOperation;
