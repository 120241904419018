import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const NoDataItem = () => {
  const classes = useStyles();

  return <Typography className={classes.root}>No existen registros</Typography>;
};

export default NoDataItem;
