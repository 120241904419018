/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { merge } from 'lodash';
import classNames from 'classnames';

import styles from './styles';
import defaultStylesDataTable, {
  minHeightHeader,
  minHeightSubHeader,
  minHeightPagination,
} from './defaultStylesDataTableComponent';

const useStyles = makeStyles(styles);

const TableList = ({
  data,
  columns,
  title,
  filtersComponent,
  perPage,
  totalRows,
  loading,
  handlePerRowsChange,
  handlePageChange,
  customStyles,
  pagination,
  noHeader,
  subHeader,
  dense,
  fixedHeaderScrollHeight,
  classCardContainer,
  ...rest
}) => {
  const classes = useStyles({
    noHeader,
    minHeightHeader,
    noSubHeader: !subHeader,
    minHeightSubHeader,
    noPagination: !pagination,
    minHeightPagination,
  });

  const stylesDataTable = useMemo(
    () => merge(defaultStylesDataTable, customStyles),
    [customStyles],
  );

  return (
    <Card className={classNames(classes.cardContainer, classCardContainer)}>
      <DataTable
        title={title}
        noHeader={noHeader}
        fixedHeader
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        subHeader={subHeader}
        subHeaderAlign="left"
        subHeaderComponent={filtersComponent}
        persistTableHead
        columns={columns}
        data={data}
        paginationPerPage={perPage}
        progressPending={loading}
        progressComponent={<CircularProgress size={75} />}
        pagination={pagination}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página',
          rangeSeparatorText: 'de',
        }}
        dense={dense}
        highlightOnHover
        className={classes.tableContainer}
        customStyles={stylesDataTable}
        {...rest}
      />
    </Card>
  );
};

TableList.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.node,
  filtersComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  perPage: PropTypes.number,
  totalRows: PropTypes.number,
  loading: PropTypes.bool,
  handlePerRowsChange: PropTypes.func,
  handlePageChange: PropTypes.func,
  customStyles: PropTypes.object,
  pagination: PropTypes.bool,
  noHeader: PropTypes.bool,
  subHeader: PropTypes.bool,
  dense: PropTypes.bool,
  fixedHeaderScrollHeight: PropTypes.string,
  classCardContainer: PropTypes.string,
};

const voidFunction = () => null;

TableList.defaultProps = {
  data: [],
  columns: [],
  title: null,
  filtersComponent: null,
  perPage: 25,
  totalRows: 0,
  loading: false,
  handlePerRowsChange: voidFunction,
  handlePageChange: voidFunction,
  customStyles: {},
  pagination: true,
  noHeader: false,
  subHeader: true,
  dense: false,
  fixedHeaderScrollHeight: '100vh',
  classCardContainer: null,
};

export default TableList;
