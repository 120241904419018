import { get } from 'lodash';

export const listTypesDigitalDocumentsDescriptions = {
  business_document: 'Documento constitucional',
  service: 'Servicio',
  source_of_founds: 'Comprobante de ingresos',
  billing: 'Facturación',
  front_document: 'DNI (dorso)',
  back_document: 'DNI (anverso)',
  dni_selfie: 'DNI (selfie)',
};

const defaultDescription = 'imagen';

export const getDescriptionDigitalDocumentByType = (type) => {
  const description = get(
    listTypesDigitalDocumentsDescriptions,
    type,
    defaultDescription,
  );
  return `Ver ${description}`;
};
